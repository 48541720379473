import { Injectable, ComponentRef, Renderer2, ElementRef, RendererFactory2, Inject } from '@angular/core';
import { NgxSpinnerService, Spinner, NgxSpinnerComponent } from 'ngx-spinner'
import { Loader } from 'fortunebit-staffhub/src/typings/common-api.typings'

interface spinnerOptions {
  attr: string,
  attr_value: string
}

interface spinnerStyle {
  style: string,
  styleValue: string
}


@Injectable({
  providedIn: 'root'
})
export class LoaderService implements Loader {
  public globalRef: ComponentRef<NgxSpinnerComponent>
  private defaultSpinnerOption: Spinner = {
    type: 'ball-spin-clockwise-fade',
    color: 'grey',
    size: 'small',
    bdColor: 'rgba(236, 236, 236)',
    fullScreen: false
  }
  constructor(@Inject(NgxSpinnerService) private spinner: NgxSpinnerService) { }

  setGlobalRef(ref: ComponentRef<NgxSpinnerComponent>) {
    this.globalRef = ref
  }

  presentGlobalLoading() {
    this.spinner.show('global', {
      type: 'ball-spin-clockwise-fade',
      fullScreen: true,
      size: 'medium',
      color: 'grey',
      bdColor: 'rgba(236, 236, 236, 0.5)',
      showSpinner: true
    })
  }

  defaultSpinnerOptionTemplate(name: string): Array<spinnerOptions> {
    return [
      { attr: 'name', attr_value: name }
    ]
  }

  hideGlobalLoading() {
    this.spinner.hide('global')
  }

  presentLoading(name: string, options: Spinner = this.defaultSpinnerOption) {
    this.spinner.show(name, options)
  }

  getSpinner(name) {
    console.log('gettingSpinner')
    this.spinner.getSpinner(name).subscribe(data => {
      console.log(data)
    })
  }

  hideLoading(name: string) {
    this.spinner.hide(name)
  }

  createSpinnerElement(options: Array<spinnerOptions>, render2: Renderer2) {
    let temp = render2.createElement('ngx-spinner')

    for (let key of options) {
      render2.setAttribute(temp, key.attr, key.attr_value)
    }
    // console.log(temp)
    return temp

  }

  createSpinnerInfoElement(name: string, options: Array<spinnerOptions>,
    render2: Renderer2) {
    let temp = render2.createElement(name)

    for (let key of options) {
      render2.setAttribute(temp, key.attr, key.attr_value)
    }

    return temp
  }

  removeChildSpinnerElement(childRef: any, parentRef: any, render2) {
    render2.removeChild(parentRef, childRef)
  }
}
