import { Injectable, Inject, Injector } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { GlobalVariables } from '../../core/globalvar.service'
import { Router, NavigationExtras } from "@angular/router"

@Injectable({
    providedIn: "root"
})
export class AuthGuardService implements CanActivate {
    constructor(
        @Inject(GlobalVariables) private globalvar: GlobalVariables,
        private router: Router
    ){}

    canActivate(route: ActivatedRouteSnapshot): boolean {

        let authInfo = this.globalvar.auth_global.access_token
        
        if (!authInfo) {
            this.router.navigate(['/login'], {replaceUrl: true})
            return false;
        }
        
        return true;
    }
}
