<div class="ba-balance-inner align-self-center card-style align-top pending parent-claim parent-content"
  data-contentfilter="pending" style="display: inline-block;padding-bottom: 5px;border-radius: 20px 0px 20px 20px;">

  <div class="icon icon-eclaim" style="padding:0px; margin:0px; background:none;">
    <div class="header-user photo_renderer"
      style="margin:auto; background:url(./assets/img/profile-face-woman.jpg) no-repeat center center; background-size:cover; width:100%; height:100%; border-radius:6666px; image-rendering: -webkit-optimize-contrast; border:1px solid #ccc;">
    </div>
  </div>



  <div>
    <div style="overflow:hidden;" class="modal fade" id="all-add-reason" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style="border-radius: 25px; padding: 18px; border: 3px solid #3a3a3a8a;">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Remark: </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body add_reason_form">
            <label for="reason">Reason</label>
            <textarea [(ngModel)]="reason" type="text" class="form-control" autocomplete="false"
              aria-describedby="distanceHelp" required="true"></textarea>
            <i><small *ngIf="reason === ''" style="color: red;">Required</small></i>
          </div>
          <div class="form-group basic relative modal-footer d-flex justify-content-center">
            <button [ngClass]="{'btn-disabled': reason === ''}" [disabled]="reason === ''" type="button"
              class="btn-c btn-blue btn-lg w-100 noicon pl5" data-bs-dismiss="modal">Save
              Remark</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <app-leave-info-popup></app-leave-info-popup>
  </div>

  <div data-target="" style="position:relative; padding-left: 54px; padding-right: 0px; cursor: pointer;">
    <!-- <h5 class="title">{{parentLeaveData.employee_name}}</h5> -->
    <!-- <div>
      <ul class="list-content">
        <li><b>Company: </b>{{companyName}}</li>
        <li><b>Department: </b>{{department}}</li>
        <li><b>Employee ID: </b>{{parentLeaveData.employee}}</li>
        <li><b>Report ID: </b>{{reports_to}}</li>
      </ul>
    </div> -->

    <div *ngIf="noLeaveAllocation == false" style="display: flex;align-items: center;margin-top: 10px;">
      <div style="display: flex;">
        <h5 class="allocation-title">Applied Leave Types</h5>
        <svg id="leave_icon" _ngcontent-mnm-c235="" xmlns="http://www.w3.org/2000/svg" data-name="Design Convert"
          viewBox="0 0 64 64">
          <defs _ngcontent-mnm-c235="">
            <style _ngcontent-mnm-c235=""></style>
          </defs>
          <title _ngcontent-mnm-c235=""></title>
          <path _ngcontent-mnm-c235="" d="M55,28H34a1,1,0,0,1,0-2H55a1,1,0,0,1,0,2Z"></path>
          <path _ngcontent-mnm-c235=""
            d="M28,57a1,1,0,0,1-.45-.11L8.66,47.45A3,3,0,0,1,7,44.76V10a3,3,0,0,1,3-3h9a1,1,0,0,1,0,2H11.34l17.09,8.1A1,1,0,0,1,29,18V56a1,1,0,0,1-.47.85A1,1,0,0,1,28,57ZM9,10.11V44.76a1,1,0,0,0,.55.9L27,54.38V18.63Z">
          </path>
          <path _ngcontent-mnm-c235=""
            d="M47,37a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L54.59,27l-8.3-8.29a1,1,0,0,1,1.42-1.42l9,9a1,1,0,0,1,0,1.42l-9,9A1,1,0,0,1,47,37Z">
          </path>
          <path _ngcontent-mnm-c235="" d="M37,47H28a1,1,0,0,1,0-2h9a1,1,0,0,0,1-1V36a1,1,0,0,1,2,0v8A3,3,0,0,1,37,47Z">
          </path>
          <path _ngcontent-mnm-c235=""
            d="M39,19a1,1,0,0,1-1-1V10a1,1,0,0,0-1-1H15a1,1,0,0,1,0-2H37a3,3,0,0,1,3,3v8A1,1,0,0,1,39,19Z"></path>
        </svg>
      </div>
    </div>

    <!-- Leave Types Buttons -->
    <!-- <div *ngIf="noLeaveAllocation == false" class="ba-balance-inner mw-100 leaveTypes"
        data-contentfilter="pending" style="display: flex; flex-direction: row; overflow: auto;" infinite-scroll
        [infiniteScrollDistance]="5" [horizontal]="true" [infiniteScrollThrottle]="3000" [alwaysCallback]="false"
        [scrollWindow]="false" [fromRoot]="true" [infiniteScrollDisabled]="infiniteScrollDisabled"
        [infiniteScrollContainer]="'.leave_type'">

        <div class="balance-title text-center nopadding card-info-frame mostly-customized-scrollbar"
          *ngFor="let leaveType of leaveTypesArray">
          <button (click)="onChangeLeaveType(leaveType)" type="button" class="btn"
            [ngClass]="{'btn-success': leaveType === selectedLeaveType}"
            style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">{{leaveType}}</button>
        </div>
      </div> -->
    <div class="leave_type">
      <div *ngIf="noLeaveAllocation == false" class="ba-balance-inner mw-100 leaveTypes" data-contentfilter="pending">
        <div #parentContainer class="icon-go-right icon-animate ng-star-inserted" style="right: -1px;top:17px"
          (click)="openClose()">
          <a class="">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#"
              xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:svg="http://www.w3.org/2000/svg"
              height="700" id="svg8" version="1.1" viewBox="0 0 185.20832 185.20832" width="700"
              style="max-width: 30px;" class="">
              <defs id="defs2" class=""></defs>
              <g id="layer1" transform="translate(244.17261,202.68451)" class="">
                <g id="g2197" transform="translate(-287.99336,0.8261165)" class="">
                  <path d="M 184.0267,-110.90647 88.823128,-22.978113" id="path1803-1"
                    style="stroke-width: 9.26041603; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1;"
                    class=""></path>
                  <path d="M 184.0267,-110.90647 88.823128,-198.83482" id="path1805-1"
                    style="stroke-width: 9.26041603; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 4; stroke-dasharray: none; stroke-opacity: 1;"
                    class=""></path>
                </g>
              </g>
            </svg>
          </a>
        </div>
        <app-ngselect-eleave-category #eleaveCategory></app-ngselect-eleave-category>

      </div>
      <div *ngIf="noLeaveAllocation == false" #childMenu
        style="padding: 12px;position:absolute;background: rgb(255, 255, 255);top: 82px;right: 15px;z-index: 99;display:none;border-bottom-right-radius: 20px;border-bottom-left-radius: 20px;box-shadow: rgb(212 209 229) -1px 14px 20px 0px;">
        <app-ngselect-advance-category #advanceCategory></app-ngselect-advance-category>
      </div>
    </div>
    <!-- End Buttons -->

    <!-- <div *ngIf="noLeaveAllocation == false && allocationOpened == true"
      style="border: 1px solid #c8c5c5; border-radius: 12px; margin: 0 !important;">
      <div class="ba-balance-inner" [ngClass]="{'active': allocationOpened == true}"> -->
    <!-- <table class="table table-striped">
          <tbody>
            <tr *ngFor="let leaveAllocationKey of leaveAllocationKeys">

              <td placement="right"
              ngbTooltip={{leaveAllocationKey}}>

                <b>{{leaveAllocationName[leaveAllocationKey]}} : </b>{{selectedLeaveDetails[leaveAllocationKey]}}
              </td>

            </tr>
          </tbody>
        </table> -->
    <!-- <ul class="list-content row" style="display: flex;  margin-right: 25px;">
          <li class="col-md-4 col-sm-6 col-xs-6" *ngFor="let leaveAllocationKey of leaveAllocationKeys">
            <b>{{leaveAllocationName[leaveAllocationKey][1]}} : </b> <a placement="right"
            ngbTooltip={{leaveAllocationName[leaveAllocationKey][0]}}>{{selectedLeaveDetails[leaveAllocationKey]}}</a> </li>
        </ul> -->

    <!-- </div>
    </div> -->
    <div class="row" *ngIf="noLeaveAllocation == false">
      <div class="col-4">
        <progress-bar [allocationDetails]="propegateSelectedFilter"></progress-bar>
      </div>
      <div class="col-8">
        <div style="display: flex; align-items: center; margin-top: 10px;">
          <div style="display: flex;">
            <h5 class="allocation-title">Leave Allocation</h5>
            <svg (mouseenter)="active($event)" (mouseleave)="disabled($event)" id="info_icon"
              xmlns="http://www.w3.org/2000/svg" data-name="Design Convert" viewBox="0 0 64 64">
              <defs></defs>
              <path
                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z" />
            </svg>
          </div>
        </div>
        <!-- <pie-chart></pie-chart> -->
        <app-eleave-allocation-table [allocationDetails]="propegateSelectedFilter"></app-eleave-allocation-table>
      </div>
    </div>
    <div *ngIf="popOverOpened == true" id="popOverAllocation"
      style="position: absolute;z-index: 12;width: 65%;font-size: 10px;top: 179px;right: 96px;border-radius: 12px;margin: 0 !important;">

      <!-- <div style="padding: 0px 12px;background: #f1f1f1;border: 1px solid #cac5c5;" class="ba-balance-inner" [ngClass]="{'active': popOverOpened == true}">
        <ul class="list-content row" style="display: flex;  margin-right: 25px;">
          <li class="col-md-4 col-sm-6 col-xs-6" *ngFor="let leaveAllocationKey of leaveAllocationKeys">
            <b>{{leaveAllocationName[leaveAllocationKey][0]}} : </b> <a placement="right"
            ngbTooltip={{leaveAllocationKey}}>{{selectedLeaveDetails[leaveAllocationKey]}}</a> </li>
        </ul>
      </div> -->
    </div>

    <div *ngIf="noLeaveAllocation == true" style="display: flex;align-items: center;margin-top: 10px;">
      <div class="empty-leave-allocation">
        <h5 style="color: white;">There is no leave allocation yet</h5>
      </div>
    </div>
  </div>
  <div class="child-filter">
    <div class="type-container">
      <app-eleave-type-status></app-eleave-type-status>
    </div>
    <!-- <div class="date-container">
      <app-ngselect-date-select></app-ngselect-date-select>
    </div> -->
  </div>
  <div *ngIf="childOpened" class="" data-moredetails="eclaim" style="display: block;">
    <div style="display: flex; flex-direction: column;">
      <div class="child_data" style="overflow-x: hidden; overflow-y: scroll; background:rgb(255, 255, 255);"
        [ngStyle]="{'height':getUserDetailContainerHeight()}">
        <div style="background-color: #ffffff;">
          <ng-template #child_eleave_component></ng-template>
        </div>
        <div *ngIf="scrollLoading" class="loader">
          <span class="">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</div>