import { Component, Inject, Input, OnInit } from '@angular/core';
import { SideMenuEmitService } from 'fortunebit-staffhub/src/core/global-emitters/glob-side-menu.emit.services'


@Component({
  selector: 'app-header-submodule',
  templateUrl: './header-submodule.component.html',
  styleUrls: ['./header-submodule.component.scss']
})
export class HeaderSubmoduleComponent implements OnInit {

  @Input('moduleName') moduleName: string
  @Input('moduleDesc') moduleDesc: string

  constructor(
    @Inject('SIDEMENUGLOBAL') private sideMenuService: SideMenuEmitService
  ) { }

  ngOnInit(): void {
  }

  activateMenu() {
    this.sideMenuService.emitEvent(true)
  }

}
