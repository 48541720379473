import {
    Validate,
    validateOrReject,
    Allow,
    Min, Max, isString, IsDate,
    IsBoolean, IsNotEmpty, IsDecimal
} from 'class-validator'

export class Eleave {
    @Allow()
    employeee: string
    @Allow()
    employee_name: string
    @Allow()
    series: string
    @Allow()
    leave_type: string
    @Allow()
    department: string
    @Allow()
    from_date: string
    @Allow()
    to_date: string
    @Allow()
    from_time: string
    @Allow()
    to_time: string
    @Allow()
    include_weekend: boolean
    @Allow()
    half_day: boolean
    @Allow()
    half_day_date: string
    @Allow()
    is_hospitalized: boolean
    @Allow()
    posting_date: string
    @Allow()
    status: string
    @Allow()
    workflow_state: string
    @Allow()
    compassionateReason: string
    @Allow()
    reason: string
    @Allow()
    employee_no: string
    @Allow()
    company: string
    @Allow()
    leave_approver_name: string
    @Allow()
    leave_approver_id: string
    @Allow()
    leave_approver: string
    @Allow()
    description: string
    @Allow()
    action: string
    @Allow()
    name: string
    @Allow()
    file_attachment: string
    @Allow()
    select_weekend: string
    @Allow()
    half_day_date_jq_data: string
    @Allow()
    @IsNotEmpty()
    total_leave_days: number
}


export interface EleaveInter {
    employeee: string
    employee_name: string
    series: string
    leave_type: string
    department: string
    from_date: string
    to_date: string
    include_weekend: boolean
    half_day: boolean
    half_day_date: string
    is_hospitalized: boolean
    posting_date: string
    status: string
    workflow_state: string
    reason: string
}