import { Injectable } from '@angular/core'
import * as jq from 'jquery'

@Injectable({
    providedIn: 'root'
})
export class jQueryServices {
    private jq_instance: JQueryStatic
    
    constructor(){
        // Start the jquery instance
        this.jq_instance = jq
    }

    getJqueryInstance(){
        return this.jq_instance
    }
}