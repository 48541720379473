import { Injectable } from '@angular/core'
import { StorageNoSql } from './storage-nosql.service'
import { GlobalVariables } from './globalvar.service'
import { NavController } from '@ionic/angular'
import { Router } from '@angular/router'
import { ToastService } from '@desktop-common-comp/services/toast.service'

@Injectable({
    providedIn: 'root'
})
export class LogOutService {
    constructor(
        private storage: StorageNoSql,
        private router: Router,
        private global: GlobalVariables,
        private toastService: ToastService,
    ) { }

    logOut() {
        this.storage.clearCollection('Auth')
            .then(() => {
                this.storage.clearCollection('User')
                    .then(() => {
                        this.global.clearGlobalData()
                            .then(() => {
                                this.storage.init().then(() => {
                                    this.router.navigateByUrl('/login', { replaceUrl: true })
                                })

                            })

                    })
            })
    }

    logOutSessionExpired() {
        this.logOut()
        this.toastService.errorToast('Session Expired', 'You session have expired')
    }
}