import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject } from 'rxjs';

export interface Department {
  id: number,
  text: string,
  description: string,
  metadata?: NgOption
}

@Component({
  selector: 'app-ngselect-department-select',
  templateUrl: './ngselect-department-select.component.html',
  styleUrls: ['./ngselect-department-select.component.scss']
})
export class NgselectDepartmentSelectComponent implements OnInit {

  department$: Observable<Department[]>
  departmentLoading = false
  departmentInput$ = new Subject<string>()
  newlyAdded: Department[] = []

  @Input('selectedDepartment') public selectedDepartment: Department[] = []
  @Input('replaceSelected') public replaceSelected = new EventEmitter<Array<Department>>()
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedDepartmentOutput') public selectedDepartmentOutput = new EventEmitter<Array<Department>>()
  @ViewChild("departmentSelect") public departmentSelect: NgSelectComponent

  constructor() { }

  ngOnInit() { }

}
