import { ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from 'class-validator';


@ValidatorConstraint({ name: 'expenseDateValid', async: false })
export class ExpenseDateValid implements ValidatorConstraintInterface {
  validate(text: string, args: ValidationArguments) {
      // Today's date
      let today_date = new Date().setHours(0,0,0,0)
      let current_date = new Date(text).setHours(0,0,0,0)

      if (current_date > today_date){
          return false
      } else {
          return true
      }
  }

  defaultMessage(args: ValidationArguments) {
    // here you can provide default error message if validation failed
    return 'Expense Date cannot be a Future date';
  }
}