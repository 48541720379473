import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  logError(message: string, stack: Error) {
    // Send errors to server here
    console.log('LoggingService: ' + message);
    console.log(stack.stack)
  }

  consoleLog(data: any) {
    if (environment.loggerActive) {
      console.log(data)
    }
  }
}

export function cs_log(instance: Object = Object, data: any = 'Compenent/Service Initalized') {
  if (environment.loggerActive) {
    console.log(`${instance.constructor.name} : ${data}`)
  }
}
