import { EventEmitter, Injectable } from '@angular/core';
import * as eleaveTypes from 'fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ChangeDetectionService {

  // public $clearAll = new Subject<boolean>()
  public $eleaveListRefresh = new Subject<boolean>()
  public $eleaveInitalLoad = new Subject<boolean>()
  public $eleaveFilterOnOff = new Subject<boolean>()
  public $eleaveCheckAllApprove = new Subject<boolean>()
  public $openLeaveAllocation = new Subject<boolean>()
  public $eleaveIndividualCheckbox = new Subject<boolean>()
  public $eclaimEmployeeCompanySelect = new Subject<boolean>()
  public $eleaveSelectedFilterDetaction = new Subject<boolean>()
  public $clearAllEmplComp = new Subject<boolean>()
  public $eleaveEmployeeChangeDetaction = new Subject<eleaveTypes.EleaveSummaryInfo>()
  public leaveAlocation$: BehaviorSubject<eleaveTypes.LeaveDetails> = new BehaviorSubject(null)

  public refreshAfterAction = new Subject<boolean>();

  constructor() { }

  triggerLeaveList() {
    this.$eleaveListRefresh.next(true)
  }

  triggerEleaveInititalLoad() {
    this.$eleaveInitalLoad.next(true)
  }

  triggerEmployeeChange(data: eleaveTypes.EleaveSummaryInfo) {
    this.$eleaveEmployeeChangeDetaction.next(data)
  }

  triggerFilterOnOff(data: Array<eleaveTypes.EleaveSummaryInfo>) {
    data.length > 0 ? this.$eleaveFilterOnOff.next(true) : this.$eleaveFilterOnOff.next(false)
  }

  triggerEmployeeHighlight(data: Array<eleaveTypes.EleaveSummaryInfo>) {
    // data.length > 0 ? this.$eleaveFilterOnOff.next(true) : this.$eleaveFilterOnOff.next(false)
  }

  async triggerAfterApproveReject() {
    this.refreshAfterAction.next(true)
  }

  triggerFilterChange(bool: boolean) {
    this.$eleaveSelectedFilterDetaction.next(bool)
  }

  // triggerClearAll() {
  //   this.$clearAll.next(true)
  // }

  triggerClearAllEmplComp() {
    this.$clearAllEmplComp.next(true)
  }

  triggerIndividualCheckbox(clicked) {
    this.$eleaveIndividualCheckbox.next(clicked)
  }

  triggerCheckAll(clicked) {
    this.$eleaveCheckAllApprove.next(clicked)
  }

  triggerSelectionEmpComp(bool: boolean) {
    this.$eclaimEmployeeCompanySelect.next(bool)
  }

  openLeaveAllocation(bool: boolean) {
    this.$openLeaveAllocation.next(bool)
  }



}
