<!-- <div class="row" style="padding:12px 0px">
    <ion-progress-bar type="determinate" color="tertiary"  [value]="progress" min="0" ></ion-progress-bar>
</div> -->

<div *ngIf="isLeaveDetails" class="row" style="margin: 12px 0px;">
    <h5 style="color: rgb(0, 0, 0);">{{leaveDetails['leave_type']}}</h5>
    <div class="card-date bar-date" style="margin: 6px 0px;background:none; position:relative;display:flex;justify-content: space-around;right: unset;"><b>{{leaveDetails['from_date']}} </b> - <b> {{leaveDetails['to_date']}}</b></div>
    <div class="col-12 card card-bar" style="margin: 0px;padding: 0px 20px 0px 5px;border-radius: 20px;background:unset;">
        <div class="item item-text-wrap" style="background: #d9d8e7;border-radius: 20px;">
            <div class="loader"  [ngStyle]="{'width': leaveDetails['progress_percent'] }">
                <p class="percent">{{leaveDetails['progress_left']}} Days</p>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="min-max">
            <span>
                {{leaveDetails['minProgress']}} Days
            </span>
            <span>
                {{leaveDetails['maxProgress']}} Days
            </span>
        </div>
    </div>
</div>
<div *ngIf="isCarryForward" class="row" style="margin: 12px 0px;">
    <h5 style="color: rgb(0, 0, 0);">{{carryForwardDetails['leave_type']}}</h5>
    <div class="card-date bar-date" style="margin: 6px 0px;background:none; position:relative;display:flex;justify-content: space-around;right: unset;"><b>{{carryForwardDetails['from_date']}} </b> - <b> {{carryForwardDetails['to_date']}}</b></div>
    <div class="col-12 card card-bar" style="margin: 0px;padding: 0px 20px 0px 5px;border-radius: 20px;background:unset;">
        <div class="item item-text-wrap" style="background: #d9d8e7;border-radius: 20px;">
            <div class="loader"  [ngStyle]="{'width': carryForwardDetails['progress_percent'] }">
                <p class="percent">{{carryForwardDetails['progress_left']}} Days</p>
            </div>
        </div>
    </div>
    <div class="col-12">
        <div class="min-max">
            <span>
                {{carryForwardDetails['minProgress']}} Days
            </span>
            <span>
                {{carryForwardDetails['maxProgress']}} Days
            </span>
        </div>
    </div>
</div>

<div *ngIf="!isLeaveDetails" style="display: flex;align-items: center;margin-top: 10px;">
    <div class="empty-leave-allocation">
      <h5 style="color: white;">There is no leave allocation yet</h5>
    </div>
</div>