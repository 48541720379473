import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leave-info-popup',
  templateUrl: './leave-info-popup.component.html',
  styleUrls: ['./leave-info-popup.component.scss']
})
export class LeaveInfoPopupComponent implements OnInit {

  public leave_type: any = "default"

  constructor() { }

  ngOnInit(): void { }

}
