import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { isEmpty } from 'class-validator';
import { Observable, Subject } from 'rxjs';
import { ParentEleaveComponent } from 'desktop-app/src/app/eleave/eleave-approve/parent-eleave/parent-eleave.component';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';


@Component({
  selector: 'pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, AfterViewInit {

  public pie: any =''
  public pie_data = {}
  public leave_allocation_keys = [
    "total_leaves",
    "expired_leaves",
    "leaves_taken",
    "pending_leaves",
    "remaining_leaves",
    "leaves_allocated",
    "carry_forward",
    "unused_carry_forward",
    "carry_forward_taken",
    "prorated_leaves"

  ]
  public allocation = 
    {'carry_forward':'Carry Forward',
    'carry_forward_taken':'Carry Forward Taken',
    'expired_leaves':'Expired Leaves',
    'leaves_allocated':'Leaves Allocated',
    'leaves_taken':'Leaves Taken',
    'total_leaves' : 'Total Leaves',
    'pending_leaves':'Pending Leaves',
    'prorated_leaves':'Prorated Leaves',
    'remaining_leaves': 'Remaining Leaves',
    'unused_carry_forward': 'unused Carry Forward'}

  
  public label_allocation = []
  public chart_value: any = []

  constructor(
    
  ) {
    ParentEleaveComponent.leaveAllocations.subscribe(value => {
      // console.log(value)
      this.chart_value=[]
      this.label_allocation = []
      if(value){
        this.leave_allocation_keys.forEach(data => {
          // console.log(data,value[data])
          this.chart_value.push(value[data])
          this.label_allocation.push(this.allocation[data])
        });
        this.createChart();
        // this.update_pie()
      }else{
        this.chart_value=[0,0,0,0,0,0,0,0,0,0,0]
        this.label_allocation = this.leave_allocation_keys
        this.createChart()
      }
     })
  }

  ngOnInit() { 
    
  }
  ngAfterViewInit():void{
    // this.createChart();
  }
  createChart() {
    if (this.pie) this.pie.destroy();
    this.pie = new Chart("MyChart", {
      plugins: [ChartDataLabels],
      type: 'doughnut',
      data: {
        labels: this.label_allocation,
        datasets: [{
          // label: 'Area and Production of Important Crops (2020-21)',
          data: this.chart_value,
          backgroundColor: [
            '#F79885',
            '#85B0F7',
            '#C992F7',
            '#F7EE92',
            '#FB84A4',
            '#B5F792',
            '#F6B461',
            '#D49C75',
            '#D0CECD',
            // 'beige',
            '#8EF3F7',
            '#DE84FB',
            'khaki'

            
          ],
          hoverOffset: 4
        }]
      },
      options: {
        layout:{
          padding: {
            right: 180
          }

        },
        // animation: true,
        responsive: true,
        aspectRatio: 2.5,
        plugins: {
          legend: {  
            display: false,
          },
          datalabels: {
            color: 'black',
            formatter: function(value, context) {
              // console.log(context.chart.data.datasets[0].data[context.dataIndex] )
              if(Number(context.chart.data.datasets[0].data[context.dataIndex]) > 0){
                return context.chart.data.labels[context.dataIndex];
              }
              else{
                return ''
              }
              
            },
            font: {
              weight: 'bold',
            },
            offset: 0,
            padding: 0
          },
        },
       

      }
    });
  }
  update_pie(){
    this.pie.data.labels = this.label_allocation
    this.pie.data.datasets[0].data = this.chart_value
    this.pie.update();
  }
  
  }
