<div>
  <div style="overflow:hidden;" class="modal fade" id="annual-leave-info" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 600px">
      <div class="modal-content" style="border-radius: 25px; padding: 18px; border: 3px solid #3a3a3a8a;">

        <div class="modal-header" style="flex-wrap: wrap;">
          <div *ngIf="leave_type == 'default'" class="modal-body add_reason_form">
            <div style="">
              <div style="position: relative; text-align: center; font-size: 26px;">
                <!-- <div class="icon icon-right icon-eleave"
                  style="position: relative;width: 35px;height:35px;top:0px !important;left:0px !important;margin-right:5px">
                  <svg style="width:25px; height:25px" xmlns="http://www.w3.org/2000/svg" data-name="Design Convert"
                    viewBox="0 0 64 64">
                    <defs>
                      <style></style>
                    </defs>
                    <title></title>
                    <path class="" d="M55,28H34a1,1,0,0,1,0-2H55a1,1,0,0,1,0,2Z"></path>
                    <path class=""
                      d="M28,57a1,1,0,0,1-.45-.11L8.66,47.45A3,3,0,0,1,7,44.76V10a3,3,0,0,1,3-3h9a1,1,0,0,1,0,2H11.34l17.09,8.1A1,1,0,0,1,29,18V56a1,1,0,0,1-.47.85A1,1,0,0,1,28,57ZM9,10.11V44.76a1,1,0,0,0,.55.9L27,54.38V18.63Z">
                    </path>
                    <path class=""
                      d="M47,37a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L54.59,27l-8.3-8.29a1,1,0,0,1,1.42-1.42l9,9a1,1,0,0,1,0,1.42l-9,9A1,1,0,0,1,47,37Z">
                    </path>
                    <path class="" d="M37,47H28a1,1,0,0,1,0-2h9a1,1,0,0,0,1-1V36a1,1,0,0,1,2,0v8A3,3,0,0,1,37,47Z">
                    </path>
                    <path class=""
                      d="M39,19a1,1,0,0,1-1-1V10a1,1,0,0,0-1-1H15a1,1,0,0,1,0-2H37a3,3,0,0,1,3,3v8A1,1,0,0,1,39,19Z">
                    </path>
                  </svg>
                </div> -->
                <div style="font-weight: bold;">
                  <label>Annual Leave</label>
                </div>
              </div>

              <button style="position: absolute;  right: 0; top: 0px;" type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
        </div>
        <div *ngIf="leave_type == 'default'"
          style="position: relative; text-align: center; font-size: 18px; font-weight: 700;"
          class="modal-body add_reason_form">
          <label for="reason">Leave Balance</label>
          <div class="searchclass"
            style="display: flex; border: 3px solid #c9c9c9; border-radius: 16px; padding: 40px; font-size: 18px;">
            <table class="table">
              <thead class="table-primary">
                <tr>
                  <th scope="col">Annual Leave Opening</th>
                  <th scope="col">Annual Leave Taken</th>
                  <th scope="col">Annual Leave Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>21.00</td>
                  <td>6.00</td>
                  <td>15.00</td>
                </tr>
              </tbody>
            </table>


            <!-- <ul class=" list-content" style="display: flex;flex-wrap: wrap;">
                  <li><b> Annual Leave : </b>0 </li>
                  <li><b> Medical Leave : </b>0 </li>
                  <li><b> Leave Without Pay: </b>0 </li>
                  </ul>
                  <ul class="list-content" style="display: flex;flex-wrap: wrap;">
                    <li><b> Maternity Leave: </b>0 </li>
                    <li><b> Paternity Leave : </b>0 </li>
                    <li><b> Compassionate Leave : </b>0 </li>
                  </ul>
                  <ul class="list-content" style="display: flex;flex-wrap: wrap;">
                    <li><b> Calamity Leave : </b>0 </li>
                    <li><b> Marriage Leave : </b>0 </li>
                  </ul> -->
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- -------------------------------- -->
<!-- Clamity Leave -->

<div>
  <div style="overflow:hidden;" class="modal fade" id="compensatory-leave-info" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 600px">
      <div class="modal-content" style="border-radius: 25px; padding: 18px; border: 3px solid #3a3a3a8a;">
        <div class="modal-header" style="flex-wrap: wrap;">
          <div *ngIf="leave_type == 'default'" class="modal-body add_reason_form">
            <div style="">
              <div style="position: relative; text-align: center; font-size: 26px;">
                <!-- <div class="icon icon-right icon-eleave"
                  style="position: relative;width: 35px;height:35px;top:0px !important;left:0px !important;margin-right:5px">
                  <svg style="width:25px; height:25px" xmlns="http://www.w3.org/2000/svg" data-name="Design Convert"
                    viewBox="0 0 64 64">
                    <defs>
                      <style></style>
                    </defs>
                    <title></title>
                    <path class="" d="M55,28H34a1,1,0,0,1,0-2H55a1,1,0,0,1,0,2Z"></path>
                    <path class=""
                      d="M28,57a1,1,0,0,1-.45-.11L8.66,47.45A3,3,0,0,1,7,44.76V10a3,3,0,0,1,3-3h9a1,1,0,0,1,0,2H11.34l17.09,8.1A1,1,0,0,1,29,18V56a1,1,0,0,1-.47.85A1,1,0,0,1,28,57ZM9,10.11V44.76a1,1,0,0,0,.55.9L27,54.38V18.63Z">
                    </path>
                    <path class=""
                      d="M47,37a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L54.59,27l-8.3-8.29a1,1,0,0,1,1.42-1.42l9,9a1,1,0,0,1,0,1.42l-9,9A1,1,0,0,1,47,37Z">
                    </path>
                    <path class="" d="M37,47H28a1,1,0,0,1,0-2h9a1,1,0,0,0,1-1V36a1,1,0,0,1,2,0v8A3,3,0,0,1,37,47Z">
                    </path>
                    <path class=""
                      d="M39,19a1,1,0,0,1-1-1V10a1,1,0,0,0-1-1H15a1,1,0,0,1,0-2H37a3,3,0,0,1,3,3v8A1,1,0,0,1,39,19Z">
                    </path>
                  </svg>
                </div> -->
                <div style="font-weight: bold;">
                  <label>Compensatory Leave</label>
                </div>
              </div>

              <button style="position: absolute;  right: 0; top: 0px;" type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
        </div>

        <div *ngIf="leave_type == 'default'"
          style="position: relative; text-align: center; font-size: 18px; font-weight: 700;"
          class="modal-body add_reason_form">
          <label for="reason">Leave Balance</label>
          <div class="searchclass"
            style="display: flex; border: 3px solid #c9c9c9; border-radius: 16px; padding: 40px; font-size: 18px;">
            <table class="table">
              <thead class="table-warning">
                <tr>
                  <th scope="col">Compensatory Leave Opening</th>
                  <th scope="col">Compensatory Leave Taken</th>
                  <th scope="col">Compensatory Leave Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>5.00</td>
                  <td>0.00</td>
                  <td>5.00</td>
                </tr>
              </tbody>
            </table>


            <!-- <ul class=" list-content" style="display: flex;flex-wrap: wrap;">
                  <li><b> Annual Leave : </b>0 </li>
                  <li><b> Medical Leave : </b>0 </li>
                  <li><b> Leave Without Pay: </b>0 </li>
                  </ul>
                  <ul class="list-content" style="display: flex;flex-wrap: wrap;">
                    <li><b> Maternity Leave: </b>0 </li>
                    <li><b> Paternity Leave : </b>0 </li>
                    <li><b> Compassionate Leave : </b>0 </li>
                  </ul>
                  <ul class="list-content" style="display: flex;flex-wrap: wrap;">
                    <li><b> Calamity Leave : </b>0 </li>
                    <li><b> Marriage Leave : </b>0 </li>
                  </ul> -->
          </div>
        </div>

      </div>
    </div>
  </div>
</div>


<!-- -------------------------------- -->
<!-- Medical Leave -->

<div>
  <div style="overflow:hidden;" class="modal fade" id="medical-leave-info" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 600px">
      <div class="modal-content" style="border-radius: 25px; padding: 18px; border: 3px solid #3a3a3a8a;">
        <div class="modal-header" style="flex-wrap: wrap;">
          <div *ngIf="leave_type == 'default'" class="modal-body add_reason_form">
            <div style="">
              <div style="position: relative; text-align: center; font-size: 26px;">
                <!-- <div class="icon icon-right icon-eleave"
                  style="position: relative;width: 35px;height:35px;top:0px !important;left:0px !important;margin-right:5px">
                  <svg style="width:25px; height:25px" xmlns="http://www.w3.org/2000/svg" data-name="Design Convert"
                    viewBox="0 0 64 64">
                    <defs>
                      <style></style>
                    </defs>
                    <title></title>
                    <path class="" d="M55,28H34a1,1,0,0,1,0-2H55a1,1,0,0,1,0,2Z"></path>
                    <path class=""
                      d="M28,57a1,1,0,0,1-.45-.11L8.66,47.45A3,3,0,0,1,7,44.76V10a3,3,0,0,1,3-3h9a1,1,0,0,1,0,2H11.34l17.09,8.1A1,1,0,0,1,29,18V56a1,1,0,0,1-.47.85A1,1,0,0,1,28,57ZM9,10.11V44.76a1,1,0,0,0,.55.9L27,54.38V18.63Z">
                    </path>
                    <path class=""
                      d="M47,37a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L54.59,27l-8.3-8.29a1,1,0,0,1,1.42-1.42l9,9a1,1,0,0,1,0,1.42l-9,9A1,1,0,0,1,47,37Z">
                    </path>
                    <path class="" d="M37,47H28a1,1,0,0,1,0-2h9a1,1,0,0,0,1-1V36a1,1,0,0,1,2,0v8A3,3,0,0,1,37,47Z">
                    </path>
                    <path class=""
                      d="M39,19a1,1,0,0,1-1-1V10a1,1,0,0,0-1-1H15a1,1,0,0,1,0-2H37a3,3,0,0,1,3,3v8A1,1,0,0,1,39,19Z">
                    </path>
                  </svg>
                </div> -->
                <div style="font-weight: bold;">
                  <label>Medical Leave</label>
                </div>
              </div>

              <button style="position: absolute;  right: 0; top: 0px;" type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
        </div>
        <div *ngIf="leave_type == 'default'"
          style="position: relative; text-align: center; font-size: 18px; font-weight: 700;"
          class="modal-body add_reason_form">
          <label for="reason">Leave Balance</label>
          <div class="searchclass"
            style="display: flex; border: 3px solid #c9c9c9; border-radius: 16px; padding: 40px; font-size: 18px;">
            <table class="table">
              <thead class="table-success">
                <tr>
                  <th scope="col">Medical Leave Opening</th>
                  <th scope="col">Medical Leave Taken</th>
                  <th scope="col">Medical Leave Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>18.00</td>
                  <td>3.00</td>
                  <td>15.00</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div>
  <div style="overflow:scroll;" class="modal fade" id="all-leave-info" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered" style="max-width: 600px">

      <div class="modal-content" style="border-radius: 25px; padding: 18px; border: 3px solid #3a3a3a8a; top: 55px;">
        <button style="position: absolute; right: 35px; top: 20px; font-size: 15px;" type="button" class="btn-close"
          data-bs-dismiss="modal" aria-label="Close"></button>

        <div class="modal-header" style="flex-wrap: wrap;">
          <div *ngIf="leave_type == 'default'" class="modal-body add_reason_form">
            <div style="">
              <div style="position: relative; text-align: center; font-size: 26px;">
                <!-- <div class="icon icon-right icon-eleave"
                    style="position: relative;width: 35px;height:35px;top:0px !important;left:0px !important;margin-right:5px">
                    <svg style="width:25px; height:25px" xmlns="http://www.w3.org/2000/svg" data-name="Design Convert"
                      viewBox="0 0 64 64">
                      <defs>
                        <style></style>
                      </defs>
                      <title></title>
                      <path class="" d="M55,28H34a1,1,0,0,1,0-2H55a1,1,0,0,1,0,2Z"></path>
                      <path class=""
                        d="M28,57a1,1,0,0,1-.45-.11L8.66,47.45A3,3,0,0,1,7,44.76V10a3,3,0,0,1,3-3h9a1,1,0,0,1,0,2H11.34l17.09,8.1A1,1,0,0,1,29,18V56a1,1,0,0,1-.47.85A1,1,0,0,1,28,57ZM9,10.11V44.76a1,1,0,0,0,.55.9L27,54.38V18.63Z">
                      </path>
                      <path class=""
                        d="M47,37a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L54.59,27l-8.3-8.29a1,1,0,0,1,1.42-1.42l9,9a1,1,0,0,1,0,1.42l-9,9A1,1,0,0,1,47,37Z">
                      </path>
                      <path class="" d="M37,47H28a1,1,0,0,1,0-2h9a1,1,0,0,0,1-1V36a1,1,0,0,1,2,0v8A3,3,0,0,1,37,47Z">
                      </path>
                      <path class=""
                        d="M39,19a1,1,0,0,1-1-1V10a1,1,0,0,0-1-1H15a1,1,0,0,1,0-2H37a3,3,0,0,1,3,3v8A1,1,0,0,1,39,19Z">
                      </path>
                    </svg>
                  </div> -->
                <div style="font-weight: bold;">
                  <label>Annual Leave</label>
                </div>
              </div>

              <!-- <button style="position: absolute;  right: 0; top: 0px;" type="button" class="btn-close"
                  data-bs-dismiss="modal" aria-label="Close"></button> -->
            </div>
          </div>
        </div>

        <div *ngIf="leave_type == 'default'"
          style="position: relative; text-align: center; font-size: 18px; font-weight: 700;"
          class="modal-body add_reason_form">
          <label for="reason">Leave Balance</label>
          <div class="searchclass"
            style="display: flex; border: 3px solid #c9c9c9; border-radius: 16px; padding: 40px; font-size: 18px;">
            <table class="table">
              <thead class="table-primary">
                <tr>
                  <th scope="col">Annual Leave Opening</th>
                  <th scope="col">Annual Leave Taken</th>
                  <th scope="col">Annual Leave Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>21.00</td>
                  <td>6.00</td>
                  <td>15.00</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

        <div class="modal-header" style="flex-wrap: wrap;">
          <div *ngIf="leave_type == 'default'" class="modal-body add_reason_form">
            <div style="">
              <div style="position: relative; text-align: center; font-size: 26px;">
               
                <div style="font-weight: bold;">
                  <label>Clamity Leave</label>
                </div>
              </div>

              <!-- <button style="position: absolute;  right: 0; top: 0px;" type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close"></button> -->
            </div>
          </div>
        </div>

        <div *ngIf="leave_type == 'default'"
          style="position: relative; text-align: center; font-size: 18px; font-weight: 700;"
          class="modal-body add_reason_form">
          <label for="reason">Leave Balance</label>
          <div class="searchclass"
            style="display: flex; border: 3px solid #c9c9c9; border-radius: 16px; padding: 40px; font-size: 18px;">
            <table class="table">
              <thead class="table-warning">
                <tr>
                  <th scope="col">Clamity Leave Opening</th>
                  <th scope="col">Clamity Leave Taken</th>
                  <th scope="col">Clamity Leave Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2.00</td>
                  <td>0.00</td>
                  <td>2.00</td>
                </tr>
              </tbody>
            </table>


          
          </div>
        </div>

        <div class="modal-header" style="flex-wrap: wrap;">
          <div *ngIf="leave_type == 'default'" class="modal-body add_reason_form">
            <div style="">
              <div style="position: relative; text-align: center; font-size: 26px;">
                <!-- <div class="icon icon-right icon-eleave"
                  style="position: relative;width: 35px;height:35px;top:0px !important;left:0px !important;margin-right:5px">
                  <svg style="width:25px; height:25px" xmlns="http://www.w3.org/2000/svg" data-name="Design Convert"
                    viewBox="0 0 64 64">
                    <defs>
                      <style></style>
                    </defs>
                    <title></title>
                    <path class="" d="M55,28H34a1,1,0,0,1,0-2H55a1,1,0,0,1,0,2Z"></path>
                    <path class=""
                      d="M28,57a1,1,0,0,1-.45-.11L8.66,47.45A3,3,0,0,1,7,44.76V10a3,3,0,0,1,3-3h9a1,1,0,0,1,0,2H11.34l17.09,8.1A1,1,0,0,1,29,18V56a1,1,0,0,1-.47.85A1,1,0,0,1,28,57ZM9,10.11V44.76a1,1,0,0,0,.55.9L27,54.38V18.63Z">
                    </path>
                    <path class=""
                      d="M47,37a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L54.59,27l-8.3-8.29a1,1,0,0,1,1.42-1.42l9,9a1,1,0,0,1,0,1.42l-9,9A1,1,0,0,1,47,37Z">
                    </path>
                    <path class="" d="M37,47H28a1,1,0,0,1,0-2h9a1,1,0,0,0,1-1V36a1,1,0,0,1,2,0v8A3,3,0,0,1,37,47Z">
                    </path>
                    <path class=""
                      d="M39,19a1,1,0,0,1-1-1V10a1,1,0,0,0-1-1H15a1,1,0,0,1,0-2H37a3,3,0,0,1,3,3v8A1,1,0,0,1,39,19Z">
                    </path>
                  </svg>
                </div> -->
                <div style="font-weight: bold;">
                  <label>Medical Leave</label>
                </div>
              </div>

              <!-- <button style="position: absolute;  right: 0; top: 0px;" type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close"></button> -->
            </div>
          </div>
        </div>

        <div *ngIf="leave_type == 'default'"
          style="position: relative; text-align: center; font-size: 18px; font-weight: 700;"
          class="modal-body add_reason_form">
          <label for="reason">Leave Balance</label>
          <div class="searchclass"
            style="display: flex; border: 3px solid #c9c9c9; border-radius: 16px; padding: 40px; font-size: 18px;">
            <table class="table">
              <thead class="table-success">
                <tr>
                  <th scope="col">Medical Leave Opening</th>
                  <th scope="col">Medical Leave Taken</th>
                  <th scope="col">Medical Leave Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>18.00</td>
                  <td>3.00</td>
                  <td>15.00</td>
                </tr>
              </tbody>
            </table>


        
          </div>
        </div>

      </div>



    </div>

  </div>
</div>

<div>
  <div style="overflow:scroll;" class="modal fade" id="annual-leave-r-info" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">

    <div class="modal-dialog modal-dialog-centered" style="max-width: 600px">

      <div class="modal-content" style="border-radius: 25px; padding: 18px; border: 3px solid #3a3a3a8a; top: 55px;">
        <button style="position: absolute; right: 35px; top: 20px; font-size: 15px;" type="button" class="btn-close"
          data-bs-dismiss="modal" aria-label="Close"></button>

        <div class="modal-header" style="flex-wrap: wrap;">
          <div *ngIf="leave_type == 'default'" class="modal-body add_reason_form">
            <div>
              <div style="position: relative; text-align: center; font-size: 26px;">
                <!-- <div class="icon icon-right icon-eleave"
                    style="position: relative;width: 35px;height:35px;top:0px !important;left:0px !important;margin-right:5px">
                    <svg style="width:25px; height:25px" xmlns="http://www.w3.org/2000/svg" data-name="Design Convert"
                      viewBox="0 0 64 64">
                      <defs>
                        <style></style>
                      </defs>
                      <title></title>
                      <path class="" d="M55,28H34a1,1,0,0,1,0-2H55a1,1,0,0,1,0,2Z"></path>
                      <path class=""
                        d="M28,57a1,1,0,0,1-.45-.11L8.66,47.45A3,3,0,0,1,7,44.76V10a3,3,0,0,1,3-3h9a1,1,0,0,1,0,2H11.34l17.09,8.1A1,1,0,0,1,29,18V56a1,1,0,0,1-.47.85A1,1,0,0,1,28,57ZM9,10.11V44.76a1,1,0,0,0,.55.9L27,54.38V18.63Z">
                      </path>
                      <path class=""
                        d="M47,37a1,1,0,0,1-.71-.29,1,1,0,0,1,0-1.42L54.59,27l-8.3-8.29a1,1,0,0,1,1.42-1.42l9,9a1,1,0,0,1,0,1.42l-9,9A1,1,0,0,1,47,37Z">
                      </path>
                      <path class="" d="M37,47H28a1,1,0,0,1,0-2h9a1,1,0,0,0,1-1V36a1,1,0,0,1,2,0v8A3,3,0,0,1,37,47Z">
                      </path>
                      <path class=""
                        d="M39,19a1,1,0,0,1-1-1V10a1,1,0,0,0-1-1H15a1,1,0,0,1,0-2H37a3,3,0,0,1,3,3v8A1,1,0,0,1,39,19Z">
                      </path>
                    </svg>
                  </div> -->
                <div style="font-weight: bold;">
                  <label>Annual Leave (Replacement)</label>
                </div>
              </div>

              <!-- <button style="position: absolute;  right: 0; top: 0px;" type="button" class="btn-close"
                  data-bs-dismiss="modal" aria-label="Close"></button> -->
            </div>
          </div>
        </div>

        <div *ngIf="leave_type == 'default'"
          style="position: relative; text-align: center; font-size: 18px; font-weight: 700;"
          class="modal-body add_reason_form">
          <label for="reason">Leave Balance</label>
          <div class="searchclass"
            style="display: flex; border: 3px solid #c9c9c9; border-radius: 16px; padding: 40px; font-size: 18px;">
            <table class="table">
              <thead class="table-warning">
                <tr>
                  <th scope="col">Annual Leave (Replacement) Opening</th>
                  <th scope="col">Annual Leave (Replacement) Taken</th>
                  <th scope="col">Annual Leave (Replacement) Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>21.00</td>
                  <td>6.00</td>
                  <td>15.00</td>
                </tr>
              </tbody>
            </table>


          </div>
        </div>

      
      </div>



    </div>

  </div>
</div>