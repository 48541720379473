import { Injectable } from '@angular/core';
import * as eclaimTypes from 'fortunebit-staffhub/src/eclaim/services/typings/eclaim-api.typings';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChangeDetectionService {

  public claim_data: eclaimTypes.MergedExpenseClaim
  public $eclaimListRefresh = new Subject<boolean>()
  public $eclaimFilterRefresh = new Subject<boolean>()
  public $eclaimEmployeeCompanySelect = new Subject<boolean>()
  public $clearAll = new Subject<boolean>()
  public $eclaimFilterOnOff = new Subject<any>()
  public $eclaimCheckAllApprove = new Subject<boolean>()
  public $refreshParentSlideData = new Subject<eclaimTypes.ExpenseSummaryInfo>()
  public $eclaimIndividualCheckbox = new Subject<boolean>()
  public $eclaimEmployeeChangeDetaction = new Subject<eclaimTypes.ExpenseSummaryInfo>()
  public eclaimApprovalActionData: BehaviorSubject<{}> = new BehaviorSubject({})
  public $eclaimApprovalActionData = this.eclaimApprovalActionData.asObservable()


  constructor() { }

  refreshClaimFilterCount() {
    this.$eclaimFilterRefresh.next(true)
  }

  triggerClearAll() {
    this.$clearAll.next(true)
  }

  triggerselectionEmpComp(bool: boolean) {
    this.$eclaimEmployeeCompanySelect.next(bool)
  }

  triggerClaimList() {
    this.$eclaimListRefresh.next(true)
  }

  triggerEmployeeChange(data: eclaimTypes.ExpenseSummaryInfo) {
    this.$eclaimEmployeeChangeDetaction.next(data)
  }

  triggerFilterOnOff(data: Array<eclaimTypes.ExpenseSummaryInfo>) {
    data.length > 0 ? this.$eclaimFilterOnOff.next(true) : this.$eclaimFilterOnOff.next(false)
  }

  triggerCheckAll(clicked) {
    this.$eclaimCheckAllApprove.next(clicked)
  }

  triggerIndividualCheckbox(clicked) {
    this.$eclaimIndividualCheckbox.next(clicked)
  }

  triggerApprovalAction(data, action) {
    this.eclaimApprovalActionData.next({ data, action })
  }

  triggerRefreshSlideData(data: eclaimTypes.ExpenseSummaryInfo) {
    this.$refreshParentSlideData.next(data)
  }

}
