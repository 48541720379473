import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-leave-alocation-progress-bar',
  templateUrl: './leave-alocation-progress-bar.component.html',
  styleUrls: ['./leave-alocation-progress-bar.component.css']
})
export class LeaveAlocationProgressBarComponent implements OnInit {

  @Input('progressPercent') public progressPercent = '63%'
  @Input('displayText') public displayText = 0

  constructor() { }

  ngOnInit() { }

}
