import {
    Component, OnInit, Input, Output, EventEmitter, ViewChild,
    ComponentRef, ViewContainerRef, Type, AfterViewInit, OnChanges,
    SimpleChanges, OnDestroy, Renderer2, ElementRef, ChangeDetectorRef,
    ChangeDetectionStrategy, AfterViewChecked
} from '@angular/core';
import { fromEvent, fromEventPattern, Observable, Subject, Subscription } from 'rxjs'
import { skip } from 'rxjs/operators';
import { jQueryServices } from 'fortunebit-staffhub/src/core/jquery.services'
import { cs_log } from '@handler/logger.handler'
import { trigger, transition, animate, style, state } from '@angular/animations';
import { ModalOptions } from '@ionic/angular';

interface modalDismissState {
    dismissType: string
    data: {}
}

export interface ModalOption {
    state: boolean
    title: string
    size: string
    component: ComponentRef<any>
    style?: string
    dismiss: EventEmitter<string>
    openClose: EventEmitter<boolean>
    submit: EventEmitter<string>
}

interface modalChild {
    closeModal: EventEmitter<Boolean>
    componentLogic?: EventEmitter<any>
}

@Component({
    selector: 'app-desktop-modal',
    templateUrl: './desktop-modal.component.html',
    styleUrls: ['./desktop-modal.component.css'],
    animations: [
        trigger('openCloseAnimation', [
            state('true', style({
                bottom: '0',
            })),
            state('false', style({
                bottom: '-100vh',
            })),
            transition('false => true', [
                animate('0.39s ease-in-out')
            ]),
            transition('true => false', [
                animate('0.39s ease-in-out')
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopModalComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges, AfterViewChecked, ModalOptions {
    @Input('state') state: boolean = false
    @Input('title') title: string = ''
    @Input('size') size: string = 'full'
    @Input('style') style: string = ''
    @Input('component') component: Type<modalChild>
    @Output('dismiss') dismiss = new EventEmitter<modalDismissState>()
    @Input('submit') submitted = new EventEmitter<modalDismissState>()
    @Input('openClose') openClose = new EventEmitter<boolean>()
    @Input('preserveInstance') preserveInstance: boolean = false
    @Input('skip1ListnerBind') skip1ListnerBind: boolean = false
    @Input('customLogicEmitter') customLogicEmitter = new EventEmitter<any>()

    @ViewChild('modalBody', { read: ViewContainerRef }) modalContainer: ViewContainerRef
    private modalContainerInstance: ComponentRef<modalChild>
    private dataFromChild: {} = {}
    public OpenClose: boolean
    // public OpenClose$ = new EventEmitter<boolean>()
    // public targetToIgnore = ["[object HTMLSpanElement]", "[object HTMLDivElement]"]
    public listner: Subscription
    public $: any

    constructor(
        public elementRef: ElementRef,
        private render2: Renderer2,
        private jQuery: jQueryServices,
        private changeDef: ChangeDetectorRef
    ) {
        this.$ = this.jQuery.getJqueryInstance()
        cs_log(this)
    }

    ngOnInit(): void { }

    ngAfterViewChecked(): void {
        // console.log('Checked runned')
    }

    startOpenCloseListenerLazy() {
        this.openClose.subscribe(data => {
            // console.log(data)
            this.OpenClose = data
            // this.OpenClose$.emit(data)
            if (data) {
                this.initListener()
            } else {
                this.endListener()
            }
            this.changeDef.detectChanges()
        })
    }

    ngAfterViewInit(): void {
        if (this.component) {
            this.renderChildComponent()
        }

        if (this.preserveInstance) {
            console.log('starting preserve instance')
            this.startOpenCloseListenerLazy()
        } else {
            this.OpenClose = this.state
            this.initListener()
            this.changeDef.detectChanges()
        }
    }

    initListener() {
        this.listner = fromEventPattern(
            () => {
                let clicked = -1
                this.$(document).on('click', 'body', (event: PointerEvent) => {
                    // console.log(event)
                    if (this.skip1ListnerBind) {
                        console.log('skipped')
                        clicked = clicked + 1
                        if (clicked > 0) {
                            if (!this.$(event.target).closest('.modaller').length && !this.$(event.target).closest('.add-balance-inner-wrap').length) {
                                console.log('inside here')

                                this.OpenClose = false
                                // For visual purpose
                                setTimeout(() => {
                                    this.closeModalClickedOutside()
                                }, 150)

                            }
                        }
                    } else {
                        if (!this.$(event.target).closest('.modaller').length && !this.$(event.target).closest('.add-balance-inner-wrap').length) {
                            console.log('from inside skipped')
                            // this.changeDef.markForCheck()
                            this.OpenClose = false
                            // For visual purpose
                            setTimeout(() => {
                                this.closeModalClickedOutside()
                            }, 150)
                        }
                    }
                })
            },
            () => {
                this.$(document).off('click', 'body')
            }
        ).subscribe()
    }

    endListener() {
        this.listner.unsubscribe()
    }

    ngOnDestroy(): void {
        // Clear child component
        // Destory element after exit

        this.modalContainerInstance.destroy()
        // this.listner.unsubscribe()
        if (this.listner) {
            this.listner.unsubscribe()
        }
        cs_log(this, 'destroyed')
        // this.changeDef.detectChanges()
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes)
    }

    closeModalClickedInside() {
        this.state = false
        this.dismiss.emit({
            'dismissType': 'clickedInside',
            'data': this.dataFromChild
        })
    }

    closeModalClickedOutside() {
        this.state = false
        this.dismiss.emit({
            'dismissType': 'clickedOutside',
            'data': this.dataFromChild
        })
    }

    submitModal() {
        this.state = false
        this.submitted.emit({
            'dismissType': 'submitted',
            'data': this.dataFromChild
        })
    }

    renderChildComponent() {
        this.modalContainerInstance = this.modalContainer.createComponent(this.component)
        this.modalContainerInstance.instance.closeModal.subscribe(data => {
            // Close Modal. Submitted Ady
            this.submitModal()
        })

        // Start Custom Logic Dispencer
        if (this.modalContainerInstance.instance.componentLogic) {
            this.customLogicEmitter.subscribe(data => {
                this.modalContainerInstance.instance.componentLogic.emit(data)
            })
        }

    }

}
