<div class="text-center">
    <div class="row">
        <div class="col-4">
            <button (click)="onChangeLeaveType('Time Off')"
                [ngClass]="{'btn-success':'Time Off' === selectedLeaveType}" type="button" class="btn"
                style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Time Off</button>
        </div>
        <div class="col-4">
            <button (click)="onChangeLeaveType('Compassionate Leave')"
                [ngClass]="{'btn-success':'Compassionate Leave' === selectedLeaveType}" type="button" class="btn"
                style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Compassionate Leave</button>
        </div>
        <div class="col-4">
            <button (click)="onChangeLeaveType('Replacement')"
                [ngClass]="{'btn-success':'Replacement' === selectedLeaveType}" type="button" class="btn"
                style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Replacement</button>
        </div>
        <!-- <div class="col-4">
            <button (click)="onChangeLeaveType('Carry Forward Token')"  [ngClass]="{'btn-success':'Carry Forward Token' === selectedLeaveType}" type="button" class="btn" style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Carry Forward Token</button>
        </div> -->
        <div class="col-4">
            <button (click)="onChangeLeaveType('Annual Leave (Replacement)')"
                [ngClass]="{'btn-success':'Annual Leave (Replacement)' === selectedLeaveType}" type="button" class="btn"
                style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Annual Leave (Replacement)</button>
        </div>
        <div class="col-4">
            <button (click)="onChangeLeaveType('Marriage Leave')"
                [ngClass]="{'btn-success':'Marriage Leave' === selectedLeaveType}" type="button" class="btn"
                style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Marriage Leave</button>
        </div>
        <div class="col-4">
            <button (click)="onChangeLeaveType('Hospitalization Leave')"
                [ngClass]="{'btn-success':'Hospitalization Leave' === selectedLeaveType}" type="button" class="btn"
                style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Hospitalization Leave</button>
        </div>
        <div class="col-4">
            <button (click)="onChangeLeaveType('Maternity Leave')"
                [ngClass]="{'btn-success':'Maternity Leave' === selectedLeaveType}" type="button" class="btn"
                style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Maternity Leave</button>
        </div>
        <div class="col-4">
            <button (click)="onChangeLeaveType('Paternity Leave')"
                [ngClass]="{'btn-success':'Paternity Leave' === selectedLeaveType}" type="button" class="btn"
                style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Paternity Leave</button>
        </div>

    </div>
    <!-- <div class="row">
        
    </div> -->
</div>