<section class="d-flex align-items-center justify-content-center">
  <div class="dropdown custom-dropdown status_dropdown" data-color="All" (click)="selectedValue($event)">
    <button class="btn dropdown-toggle w-100 d-flex align-items-center justify-content-between status__btn"
      type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-offset="0,12"
      style="padding: 0px 12px;height: unset;">
      All
    </button>
    <ul class="dropdown-menu status_change" aria-labelledby="dropdownMenuButton1"
      style="z-index: 1001; cursor: pointer;">
      <li><a class="dropdown-item" data-class="All">All</a></li>
      <li><a class="dropdown-item" data-class="Approved">Approved</a></li>
      <li><a class="dropdown-item" data-class="Pending">Pending</a></li>
      <li><a class="dropdown-item" data-class="Rejected">Rejected</a></li>
      <li><a class="dropdown-item" data-class="Cancelled">Cancelled</a></li>
    </ul>
  </div>
  <!--  -->
</section>