<div class="header-area bg-base">
  <img class="ba-nose-img" src="./assets/img/icon/1.png" alt="img">
  <div class="container">
    <div class="header-top">
      <div class="row">
        <div class="col-6">
          <p>8:33</p>
        </div>
        <div class="col-6 text-end">
          <ul>
            <li><img src="./assets/img/icon/37.png" alt="img"></li>
            <li><img src="./assets/img/icon/38.png" alt="img"></li>
            <li><img src="./assets/img/icon/39.png" alt="img"></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 col-3 align-self-center">
        <div class="menu-bar" (click)="activateMenu()">
          <img style="display:inline-block; width: 25px;vertical-align: top;" src="./assets/img/icon/0.png" alt="logo">
        </div>
      </div>
      <div class="col-sm-4 col-6 text-center align-self-center text-center">
        <a href="#" class="logo">
          <!--<img src="https://www.s7template.com/tf/uikitpro/assets/img/logo.png" alt="logo">-->
        </a><a href="#" class="logo title">{{moduleName}}<span class="subtext">
            {{moduleDesc}}</span>
        </a>

      </div>
      <div class="col-sm-4 col-3 align-self-center text-end">
        <ul class="header-right">
          <!-- <li>
                        <a href="#" class="modaller" data-modaltarget="#modal_notification">
                            <i class="fa fa-bell animated infinite swing"></i>
                            <span class="badge">16</span>
                        </a>
                    </li> -->
          <li>
            <a class="header-user" href="/"><img src="./assets/img/user.png" alt="img"></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>