// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: environmentType = {
    production: true,
    loggerActive: true,
    type: 'mobile',
    // apiUrl: 'http://127.0.0.1:7000'
    // apiUrl: 'https://apieclaim.fortunebit.my',
    // apiUrl: 'http://192.168.10.41:8033',
    // hostUrl: 'http://192.168.10.41',
    // apiUrl: 'http://192.168.10.41:8033',
    // hostUrl: 'http://10.105.0.25:86',
    apiUrl: 'https://apiv2.staffhub.tektician.com',
    hostUrl: 'http://go.setiaawan.com',
    // hostUrl: 'http://192.168.10.111',
    // hostUrl: 'https://go.setiaawan.com',
    // apiUrl: "https://arvindev.tektician.com",
    // apiUrl: "https://ramyadebug.tektician.com",
    storageKeysToInitialized: [{ 'key': 'isLogin' }, { 'key': 'email' }, { 'key': 'authUser', 'jsonparse': true }, { 'key': 'userRole' }, { 'key': 'employeeID' }, { 'key': 'access_token' }],
    storageNamespaceKeys: ["fortunebit_staffhub"],
    storageCollectionKeys: ["Auth", "User"],
    appVersion: '1.0.12',
    appVersionMock: '1.0.12'
};


type environmentType = {
    production: boolean,
    apiUrl: string,
    hostUrl: string,
    storageKeysToInitialized: Array<any>,
    storageNamespaceKeys: Array<any>,
    storageCollectionKeys: Array<any>,
    loggerActive: boolean,
    type: string,
    appVersion: string,
    appVersionMock: string
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.