import { Inject, Injectable } from "@angular/core";
import { HttpServiceService } from "../../core/http-service.service";
import { Observable, Observer, interval } from "rxjs";
import * as eleaveTypes from "./typings/eleave-api.typings";

@Injectable({
  providedIn: 'root'
})

export class ExpenseEleaveApi {

  constructor(
    @Inject(HttpServiceService) private http: HttpServiceService
  ) { }

  getLeaveBalanceList(data: eleaveTypes.GetCommonInput) {
    return new Observable((observer: Observer<Array<eleaveTypes.GetLeaveBalanceDatas>>) => {
      this.http.post('/users/getLeaveBalanceList', data).then((data: Array<eleaveTypes.GetLeaveBalanceDatas>) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getNewLeaveBalanceList(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getNewLeaveBalanceList', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getLeaveApplicationList(param: eleaveTypes.EleaveLisingFilterMeta) {
    return new Observable((observer: Observer<Array<eleaveTypes.LeaveApplicationDatas>>) => {
      this.http.get('/users/getLeaveApplicationList', param).then((data: Array<eleaveTypes.LeaveApplicationDatas>) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getUserLeaveSummary(data: eleaveTypes.GetCommonInput) {
    return new Observable((observer: Observer<Array<eleaveTypes.UserAndAprooverLeaveSummaryDatas>>) => {
      this.http.post('/users/getUserLeaveSummary', data).then((data: Array<eleaveTypes.UserAndAprooverLeaveSummaryDatas>) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getLeaveBalanceOn(body: eleaveTypes.EleaveLeaveBalanceOnArgs) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getLeaveBalanceOn', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getLeaveApproverSummaryList(body: eleaveTypes.GetCommonInput) {
    return new Observable((observer: Observer<Array<eleaveTypes.UserAndAprooverLeaveSummaryDatas>>) => {
      this.http.post('/users/getLeaveApproverSummaryList', body).then((data: Array<eleaveTypes.UserAndAprooverLeaveSummaryDatas>) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getLeaveApplicationDetails(data: eleaveTypes.GetEleaveDetailInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getSingleLeaveApplication', data).then(data => {
        observer.next(data)
        observer.complete()

      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getFiscalYearList() {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/getFiscalYearList').then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  approveAllLeaves(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/approveAllLeaves', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  approveRejectLeaveApplicationByCheck(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/approveRejectLeaveApplicationByCheck', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  approveRejectIndividualLeaves(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/approveRejectIndividualLeaves', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }


  getApprovalLeaveList(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getApprovalLeaveList', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getHrApproveLeaveList(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getHrApproveLeaveList', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  // //////////////////////
  getHrApproveLeaveListNew(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.postAfterCancel('/users/getHrApproveLeaveList', body, {}, 'getHrApproveLeaveList').then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }
  // //////////////////////


  getApprovalLeaveListByUser(body: eleaveTypes.EleaveParentBaseArgs) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getApprovalLeaveListByUser', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getHrEmployeeList(body: eleaveTypes.EleaveParentBaseArgs) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getHrEmployeeList', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  isLeaveApprover(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/isLeaveApprover', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  fetchHourlyDetails(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/fetchHourlyDetails', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getCompanyList(body) {
    // console.log(body)
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getCompanyList', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  updateLeaveApplication(body) {
    // console.log(body)
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/updateLeaveApplication', { 'data': body }).then(data => {
        // console.log(data)
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  setLeaveApplication(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/createLeaveApp', { 'data': body }).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getNumberOfLeaveDays(body: eleaveTypes.GetNumberOfLeaveInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getNumberOfLeaveDays', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getLeaveWeekendList(body: eleaveTypes.GetNumberOfWeekendAndHalfdayInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getLeaveWeekendList', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getHalfdayDatesList(body: eleaveTypes.GetNumberOfWeekendAndHalfdayInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getHalfdayDatesList', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  // get leave_type selection 'Time Off' check_hourly

  getLeaveTypeTimeoff(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getLeaveTypeTimeoff', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getLeaveTypeList() {
    return new Observable((observer: Observer<any>) => {
      this.http.get('/users/getLeaveType').then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getHrEmployeeLeaveDetails(body: eleaveTypes.EmployeeLeaveDetailsArgsHR) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getHrEmployeeLeaveDetails', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  updateHrLeaveApplicationRemark(body: any) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/updateHrLeaveApplicationRemark', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  uploadEleaveAttachment(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/uploadEleaveAttachment', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  deleteEleaveAttachment(body) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/deleteEleaveAttachment', body).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

}
