import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { EleaveListingService } from 'desktop-app/src/app/eleave/eleave-listing/services/eleave-listing.service';
import { ChangeDetectionService } from 'desktop-app/src/app/eleave/shared/change-detection.service';
import { ExpenseEleaveApi } from 'fortunebit-staffhub/src/libs';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

interface TypeList {
  value: string
  description: string
}

@Component({
  selector: 'app-ngselect-type-leave-select',
  templateUrl: './ngselect-type-leave-select.component.html',
  styleUrls: ['./ngselect-type-leave-select.component.scss']
})
export class NgselectTypeLeaveSelectComponent implements OnInit, AfterViewInit {

  public typeLoading = false
  public typeInput$ = new Subject<string>()
  public typeList: TypeList[] = []
  public typeSubs: Subscription[] = []

  @Input('typeInit') public typeInit: string = ''
  @ViewChild("typeSelect") public typeSelect: NgSelectComponent

  constructor(
    @Inject(ExpenseEleaveApi) private eleaveAPI: ExpenseEleaveApi,
    @Inject(ChangeDetectionService) private changeDetact: ChangeDetectionService,
    @Inject(EleaveListingService) private eleaveListingService: EleaveListingService
  ) {
    // this.typeStatus = this.typeList[0]
    this.typeInit = 'All'
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getLeaveTypeSelect()
    this.trackTypeSelected()
    this.listenFilterSelection()
  }

  getLeaveTypeSelect() {
    this.eleaveAPI.getLeaveTypeList().subscribe(data => {
      this.typeList = data['results'].map(item => item.value)
    })
  }

  trackTypeSelected() {
    this.typeSubs.push(this.typeSelect.changeEvent.subscribe(type => {
      console.log({ type });
      this.eleaveListingService.setLeaveType(type)
      this.eleaveListingService.triggerChangeDetection()
    }))
  }

  listenFilterSelection() {
    this.changeDetact.$eleaveSelectedFilterDetaction.pipe(
      debounceTime(100),
      filter(bool => bool === false)
    ).subscribe(bool => {
      if (!bool) {
        this.typeInit = 'All'
      }
    })
  }


}
