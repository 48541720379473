import {ExpenseClaim, ExpenseClaimDetail} from './expense_claim.model'
import {Auth, Login, User,  
    AuthType, LoginType, UserType, ForgotPassword} from './login.model'
import { registerSchema, ValidationSchema } from 'class-validator'
import { Injectable } from '@angular/core'

// Expose to Schema globally
export const schema = {ExpenseClaim, ExpenseClaimDetail, Auth, Login, User}

@Injectable({
    providedIn: 'root'
})
export class ModelInit{
    constructor(){
        this.registerSchemaInit()
    }

    registerSchemaInit(){
        // Register all Schema
        for (const key in schema){
            console.log('Register')
            let validationProperties: ValidationSchema = {
                name: key,
                properties: schema[key]
            }

            registerSchema(validationProperties)
        }
    }
}

// Expose Type Interface globally
export {
    AuthType,
    LoginType,
    UserType
}

// Expose Object Interface globally
export {
    Auth,
    Login,
    User,
    ForgotPassword
}