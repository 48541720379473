import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastService } from '@desktop-common-comp/services/toast.service';
import { EleaveCore } from 'fortunebit-staffhub/src/eleave/services/eleave-core';
import * as moment from 'moment';
import { ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator';
import { EleaveMetaData, MetaData } from 'fortunebit-staffhub/src/eleave/services/eleave-metadata';
import { ChangeDetectionService } from '../shared/change-detection.service';
import * as leaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'
import { GlobalVariables } from 'fortunebit-staffhub/src/libs';
import { debounceTime, filter, skip } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { attachement } from 'fortunebit-staffhub/src/typings/common-api.typings';
import { FileSelectorService } from 'fortunebit-staffhub/src/services/file-selector.service';
import { EleaveListingService } from '../eleave-listing/services/eleave-listing.service';


export interface TempData {
  id: number,
  text: string,
  description: string,
}

export interface HalfdayTemp {
  id: number,
  text: string,
  halfdays: string
}

@Component({
  selector: 'app-create-leave.page',
  templateUrl: './create-leave.page.html',
  styleUrls: ['./create-leave.page.scss']
})
export class CreateLeavePage implements OnInit, OnDestroy, AfterViewInit {

  @Output('closeModal') closeModal = new EventEmitter<boolean>()

  public leave_balance: leaveTypes.LeaveAllocationData;
  public leaveAlocationheaders: string[] = ['Leave Type', 'Leaves Allocated', 'Carry Forward', 'Total Allocated Leaves', 'Used Leaves', 'Used CF Leaves', 'Expired CF Leaves', 'Available Leaves', 'Pro-rated Leaves'];
  public leaveAlocation: leaveTypes.LeaveDetails = null
  public leaveBalanceShow: boolean = false
  public leaveBalanceEmpty: boolean = false
  public leaveBalanceOn: boolean = false
  public initialRenderInput: boolean = false
  public leaveBalance: number
  public hoursLessThan_0: boolean = false


  public eleaveMetaData: MetaData
  public form: ClassValidatorFormGroup
  public toDate: string
  public timeOff: boolean = false
  public halfday: boolean = false
  public gotAttachment: boolean = false
  public totalLeaveDaysShow: boolean = false
  public total_leave_days: number
  public readOnlyTotalLeave: boolean = true
  public selected_files: attachement | null = null
  public timeInputShow: boolean = false
  public HalfdayCheckboxShow: boolean = false
  public includedWeekend: boolean = false
  public HalfdayInputShow: boolean = false
  public HalfdayDropdownShow: boolean = false
  public hoursMoreThan_4: boolean = false
  public description: string
  public compassionateReasonForm: boolean = false
  public compassionateLeaveReasonList: Array<any>
  public weekendDatesList: Array<any>
  public selectedWeekendTemp: TempData[] = []
  public selectedHalfdayTemp: HalfdayTemp[] = []
  public half_day_date: string
  public formValidation: boolean = true
  public products = [
    { code: 1, name: 'dadad', category: 'llllll', quantity: 3 }
  ]

  public dataChanges = {
    // 'employee': undefined,
    'leave_type': undefined,
    'from_date': undefined,
    'from_time': undefined,
    'to_date': undefined,
    'to_time': undefined,
    'half_day': undefined,
    'half_day_dates': undefined,
    'include_weekend': undefined,
    'weekend_dates': undefined,
    'description': undefined
  }
  header: any;

  constructor(
    @Inject(ToastService) public toast: ToastService,
    @Inject(EleaveCore) public eleaveCore: EleaveCore,
    @Inject(GlobalVariables) private global: GlobalVariables,
    @Inject(EleaveMetaData) private eleaveMeta: EleaveMetaData,
    @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
    @Inject(FileSelectorService) private fileService: FileSelectorService,
    @Inject(ChangeDetectionService) private changeDetect: ChangeDetectionService,
    @Inject(EleaveListingService) private eleaveListingService: EleaveListingService
  ) {
    this.eleaveMetaData = this.eleaveMeta.initMetaData()
    this.form = this.eleaveMetaData.Eleave.form
    this.formListenerLeaveType()
  }

  resetOnAnualLeaveSelection() {
    this.form.controls['to_date'].setValue("")
    this.form.controls['to_date'].setValidators([Validators.required])
    this.form.controls['to_date'].updateValueAndValidity()
    this.form.controls['from_date'].setValue("")
    this.form.controls['from_date'].setValidators([Validators.required])
    this.form.controls['from_date'].updateValueAndValidity()
    this.totalLeaveDaysShow = false
    this.leaveBalanceOn = false
    this.selectedWeekendTemp = []
    this.form.controls['include_weekend'].setValue(false)
    this.selectedHalfdayTemp = []
    this.HalfdayInputShow = false
    this.form.controls['half_day'].setValue(false)
    this.form.controls['description'].setValue('')
  }

  resetOnFromDateSelaction() {
    this.form.controls['to_date'].setValue("")
    this.form.controls['to_date'].setValidators([Validators.required])
    this.form.controls['to_date'].updateValueAndValidity()
    this.totalLeaveDaysShow = false
    this.leaveBalanceOn = false
    this.selectedWeekendTemp = []
    this.form.controls['include_weekend'].setValue(false)
    this.selectedHalfdayTemp = []
    this.form.controls['half_day'].setValue(false)
    this.form.controls['description'].setValue('')
  }

  dateCurrentPost() {
    this.form.controls['posting_date'].setValue(moment().format('YYYY-MM-DD'))
    this.form.controls['posting_date'].updateValueAndValidity()
  }

  ngOnInit(): void {
    this.initNewLeaveBalance()

  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void { }

  submitForm() {
    if (this.gotAttachment) {
      this.eleaveCore.submitEleave(this.form.getRawValue()).then((data: any) => {
        this.eleaveCore.upload_attachement(this.selected_files, data['message']['message']).then(data2 => {
          this.closeModal.emit(true)
          this.changeDetect.triggerLeaveList()
        })
      }).catch(error => {
        console.log(error, 'whilesubmit')
        this.toast.errorToast(`Error Create leave. ${error?.error?.message}`, 'Error')
      })

    } else {
      this.eleaveCore.submitEleave(this.form.getRawValue()).then(() => {
        this.closeModal.emit(true)
        // Emit change event
        // this.emitter.emitEventListener('true')
        this.changeDetect.triggerLeaveList()
      }).catch(error => {
        let err = error.error
        console.log(error, 'whilesubmit')
        this.toast.errorToast(`Error Create leave. ${err.message}`, 'Error')
      })
    }

  }

  leaveTypeSelection() {
    if (this.form.controls['leave_type'].value === 'Time Off') {
      return false
    }
    return true
  }

  formListenerLeaveType() {
    const args: leaveTypes.EleaveLeaveBalanceOnArgs = new leaveTypes.EleaveLeaveBalanceOnArgs()

    this.dataChanges.leave_type = this.form.controls.leave_type.valueChanges.subscribe(type => {
      // console.log({ type });
      this.leaveAlocation = this.leave_balance.leave_allocation[type]
      this.leaveAlocation == undefined || this.leaveAlocation == null ? [this.leaveBalanceShow = false, this.leaveBalanceEmpty = true] : [this.leaveBalanceShow = true, this.leaveBalanceEmpty = false]
      args.employee = this.global.user_global.employee_id
      args.leave_type = type
      this.leaveBalanceOn = false
      this.HalfdayCheckboxShow = true
      this.dateCurrentPost()
      this.resetOnAnualLeaveSelection()

      // Validation for time-off --------------------

      if (this.form.controls['leave_type'].value == "Time Off") {
        this.timeOff = true
        this.timeInputShow = true
        this.HalfdayCheckboxShow = true
        this.HalfdayDropdownShow = false
        this.hoursMoreThan_4 = false
        this.dataChanges.from_date = this.form.controls.from_date.valueChanges.pipe(filter(frmD => frmD !== null && frmD !== '' && this.form.controls['leave_type'].value === "Time Off")).subscribe(frmD => {
          // console.log({ frmD });
          if (frmD) {
            this.dateCurrentPost()
            this.form.controls['to_date'].setValue(this.form.controls['from_date'].value)
            this.form.controls['half_day'].setValue(false)
            this.HalfdayInputShow = false
            this.totalLeaveDaysShow = true
            setTimeout(() => {
              this.eleaveCore.determineNumberOfLeaveDays(this.form)
            }, 100);
          }
        })

        this.dataChanges.to_time = this.form.controls.to_time.valueChanges.subscribe(toTime => {
          // console.log({ toTime });
          let startTime = moment(this.form.controls['from_time'].value, 'hh:mm:ss a')
          let endTime = moment(toTime, 'hh:mm:ss a')
          let timeInHours = endTime.diff(startTime, 'minutes') / 60
          timeInHours >= 4 ? this.hoursMoreThan_4 = true : this.hoursMoreThan_4 = false
          timeInHours < 4 ? this.total_leave_days = timeInHours / 10 : this.total_leave_days
          this.hoursMoreThan_4 === true ? this.form.controls['to_time'].setErrors({ 'incorrect': true }) : this.form.markAsTouched()
        })

        this.dataChanges.half_day = this.form.controls.half_day.valueChanges.pipe(skip(1), filter(halD => this.form.controls['leave_type'].value === "Time Off")).subscribe(halD => {
          // console.log({ halD });
          if (halD) {
            this.HalfdayInputShow = halD
            this.hoursMoreThan_4 = false
            this.HalfdayDropdownShow = false
            this.form.controls['half_day_date'].setValue(this.form.controls['from_date'].value)
            this.form.controls['from_time'].setValue('00:00')
            this.form.controls['to_time'].setValue('00:00')
            this.eleaveCore.determineNumberOfLeaveDays(this.form)
          } else {
            this.HalfdayInputShow = halD
            this.form.controls['half_day_date'].setValue("")
            this.eleaveCore.determineNumberOfLeaveDays(this.form)
          }
        })

        // End of validation for time-off --------------------

      }
    })

    this.dataChanges.include_weekend = this.form.controls.include_weekend.valueChanges.pipe(filter(halfD => this.form.controls['leave_type'].value !== "Time Off")).subscribe(weekE => {
      // console.log({ weekE });
      if (weekE) {
        this.includedWeekend = weekE
        this.form.controls['from_date'].value != undefined && this.form.controls['to_date'].value != undefined ? this.includedWeekend = true : this.includedWeekend = false
        this.form.controls['select_weekend'].setErrors({ 'incorrect': true }, { emitEvent: false })
        this.form.updateValueAndValidity({ emitEvent: true })
        this.eleaveCore.determineLeaveWeekendList(this.form)
      } else {
        this.includedWeekend = weekE
        this.selectedWeekendTemp = []
        this.form.controls['select_weekend'].reset('')
        this.form.controls['select_weekend'].clearValidators()
        this.form.controls['select_weekend'].setErrors(null, { emitEvent: false })
        this.form.controls['select_weekend'].updateValueAndValidity()
        this.form.updateValueAndValidity({ emitEvent: true })
      }
    })

    this.dataChanges.half_day = this.form.controls.half_day.valueChanges.pipe(skip(1), filter(halfD => this.form.controls['leave_type'].value !== "Time Off")).subscribe(halfD => {
      // console.log({ halfD });
      this.eleaveCore.determineNumberOfLeaveDays(this.form)
      if (halfD) {
        this.HalfdayDropdownShow = halfD
        this.form.controls['from_date'].value != undefined && this.form.controls['to_date'].value != undefined ? this.halfday = true : this.halfday = false
        this.form.controls['half_day_date_jq_data'].setErrors({ 'incorrect': true }, { emitEvent: false })
        this.form.updateValueAndValidity({ emitEvent: true })
        this.eleaveCore.determineHalfdayDatesList(this.form)
      } else {
        this.HalfdayDropdownShow = halfD
        this.selectedHalfdayTemp = []
        this.form.controls['half_day_date_jq_data'].reset('')
        this.form.controls['half_day_date_jq_data'].updateValueAndValidity()
        this.form.updateValueAndValidity({ emitEvent: true })
        this.halfday = false
      }
    })

    this.dataChanges.from_date = this.form.controls.from_date.valueChanges.pipe(debounceTime(500), filter(frmD => frmD !== undefined && frmD !== '' && this.form.controls['leave_type'].value !== "Time Off")).subscribe(frmD => {
      // console.log({ frmD });
      args.date = frmD
      this.resetOnFromDateSelaction()
    })

    this.dataChanges.to_date = this.form.controls.to_date.valueChanges.pipe(debounceTime(100), filter(toD => toD !== undefined && toD !== null && toD !== '' && this.form.controls['leave_type'].value !== "Time Off")).subscribe(toD => {
      // console.log({ toD });
      args.to_date = toD
      this.leaveBalanceOn = true
      this.selectedWeekendTemp = []
      this.selectedHalfdayTemp = []
      this.form.controls['include_weekend'].setValue(false)
      this.form.controls['half_day'].setValue(false)
      this.totalLeaveDaysShow = true
      this.eleaveCore.getLeaveBalanceOn(args).then((data: number) => {
        // console.log({ data });
        this.leaveBalance = 0
        if (data) {
          this.leaveBalance = data
        }
      })
      setTimeout(() => {
        this.eleaveCore.determineNumberOfLeaveDays(this.form)
      }, 200);

    })

    this.form.controls.half_day_date_jq_data.valueChanges.pipe(debounceTime(500), filter(halfD => halfD !== undefined && this.HalfdayDropdownShow == true)).subscribe(halfD => {
      // console.log({ halfD });
      halfD === '' && this.HalfdayDropdownShow == true ? [this.form.controls['half_day_date_jq_data'].setErrors({ 'incorrect': true }, { emitEvent: false }), this.form.updateValueAndValidity({ emitEvent: true })] : [this.form.controls['half_day_date_jq_data'].clearValidators(), this.form.controls['half_day_date_jq_data'].setErrors(null, { emitEvent: false }), this.form.updateValueAndValidity({ emitEvent: true }), this.form.updateValueAndValidity({ emitEvent: true })]
      this.eleaveCore.determineNumberOfLeaveDays(this.form)
    })

    this.form.controls.select_weekend.valueChanges.pipe(debounceTime(500), filter(selW => selW !== undefined && this.includedWeekend == true)).subscribe(selW => {
      // console.log({ selW });
      selW === '' && this.includedWeekend == true ? [this.form.controls['select_weekend'].setErrors({ 'incorrect': true }, { emitEvent: false }), this.form.updateValueAndValidity({ emitEvent: true })] : [this.form.controls['select_weekend'].clearValidators(), this.form.controls['select_weekend'].setErrors(null, { emitEvent: false }), this.form.updateValueAndValidity({ emitEvent: true })]
      this.eleaveCore.determineNumberOfLeaveDays(this.form)
    })

  }

  selectedFile(event) {
    this.fileService.fetchSelectedFile(event).then((attachDoc: attachement) => {
      // console.log(attachDoc, 'attachDoc');
      this.selected_files = attachDoc
      this.gotAttachment = true
    }).catch(error => {
      this.toast.errorToast('', error)
    })
  }

  removeContent() {
    this.selected_files = null
    this.gotAttachment = false
  }



  selectedWeekend(data: any) {
    this.selectedWeekendTemp = data
    this.form.controls['select_weekend'].setValue(this.selectedWeekendTemp.map((date) => date.text).join())
  }

  selectedHalfday(data: any) {
    this.selectedHalfdayTemp = data
    this.form.controls['half_day_date_jq_data'].setValue(this.selectedHalfdayTemp.map((date) => date.text).join())
  }


  initNewLeaveBalance() {
    this.eleaveListingService.initLeaveBalance().then((balance: leaveTypes.LeaveAllocationData) => {
      this.leave_balance = balance
      this.changeDetectorRef.detectChanges()
    })
  }




}
