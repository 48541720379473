import { Component, ViewChild, ViewContainerRef, AfterViewInit, Renderer2, ElementRef, Inject } from '@angular/core';
import { ModalService } from 'fortunebit-staffhub/src/core/modal.service';
import { NgxSpinnerComponent } from 'ngx-spinner'
import { LoaderService } from '@desktop-common-comp/services/loader.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('globalModalDirective', { read: ViewContainerRef }) modalRef: ViewContainerRef
  public spinner: any
  public spinnerMsg: any
  constructor(
    private modalMobileService: ModalService,
    @Inject(LoaderService) public loader: LoaderService,
  ) { }

  ngOnInit() {
    this.loader.presentGlobalLoading()
  }

  ngAfterViewInit(): void {
    this.modalMobileService.setRootParentViewRef(this.modalRef)
    this.loader.hideGlobalLoading()
  }
}
