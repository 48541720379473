import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eattendance',
  templateUrl: './eattendance.component.html',
  styleUrls: ['./eattendance.component.scss']
})
export class EattendanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
