import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { Observable, Subject } from 'rxjs';

export interface Year {
  value: number
  metadata?: NgOption
}

@Component({
  selector: 'app-ngselect-date-select',
  templateUrl: './ngselect-date-select.component.html',
  styleUrls: ['./ngselect-date-select.component.scss']
})
export class NgselectDateSelectComponent implements OnInit {

  


  constructor(
   
  ) { }


  ngOnInit() { }

  ngAfterViewInit(): void {
  
  }

  dataInterceptor(data: any) {


  }


}
