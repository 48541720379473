import { Component, OnInit, Inject, ElementRef, AfterViewInit, ViewContainerRef, ViewChild } from '@angular/core';
import { SideMenuEmitService } from 'fortunebit-staffhub/src/core/global-emitters/glob-side-menu.emit.services';
import { GlobalVariables } from 'fortunebit-staffhub/src/libs';
import { AuthLogoutService } from 'fortunebit-staffhub/src/auth/auth_logout.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit, AfterViewInit {

  public moduleList: boolean = false
  public moduleApproval: boolean = false
  public state: boolean = false
  public loggedInUserData: any = {}

  constructor(
    @Inject('SIDEMENUGLOBAL') private sideMenuService: SideMenuEmitService,
    @Inject(AuthLogoutService) private authLogout: AuthLogoutService,
    private ref: ElementRef,
    @Inject(GlobalVariables) private global: GlobalVariables
  ) { }

  ngAfterViewInit(): void {
    // Register template ref
    this.sideMenuService.registerComponent(this.ref).subscribe((stateData) => {
      // Handle state inside components
      this.setState()
    })
  }

  ngOnInit(): void {
    this.loggedInUserData = this.global.user_global
  }

  getState() {
    return this.state
  }

  setState() {
    if (this.state == true) {
      this.state = false
    } else {
      this.state = true
    }
  }

  openListing() {
    this.moduleList = !this.moduleList
  }

  openApproval() {
    this.moduleApproval = !this.moduleApproval
  }


  logout() {
    this.sideMenuService.emitEvent(false)
    this.authLogout.logOut()
  }


}
