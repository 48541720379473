import { Injectable } from "@angular/core";
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';


@Injectable({
	providedIn: "root"
})
export class LoaderService  {
	constructor(private loader: LoadingController, private alert: AlertController, private toast: ToastController) {}

	async presentLoading(message="Please Wait ...."){
		const loading = await this.loader.create({
			message,
		  });
		await loading.present();

		return loading
	}

	async presentAlert(header, message){
		const alert = await this.alert.create({
			header,
			subHeader: 'Subtitle',
			message,
			backdropDismiss: true,
			buttons: ['OK']
		  });
	  
		await alert.present();
	}

    async presentToast(header, message, color="success", buttons=[]){
        const toast = await this.toast.create({
            header,
            message,
            position: 'bottom',
            buttons,
            color,
            duration: 2000
        })

        await toast.present()
    }

    async presentErrorToast(header, message, color="danger", buttons=[]){
        const toast = await this.toast.create({
            header,
            message,
            position: 'bottom',
            buttons,
            color,
            duration: 3000
        })

        await toast.present()
    }
}
