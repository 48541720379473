<div class="container" style="margin-left: 30px; margin-top: 30px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="theform" style="position:relative;">
                    <!-- <div class="menu-close modal-close modaller"  data-dismiss="modal" (click)="closeModal()">
                            <i class="la la-times"></i>
                        </div> -->
                    <form [formGroup]="form" (ngSubmit)="submitForm()">
                        <div class="add_claim_item_form" id="" style="">
                            <div class="modal-header ">
                                <h5 class="modal-title nomargintb nopaddingtb">Add Leave Application</h5>
                            </div>

                            <!-- <div class="form-group">
                                    <label for="postingDate">Posting Date</label>
                                    <input formControlName="posting_date" type="date" class="form-control" value="" id="postingDate" aria-describedby="dateHelp" placeholder="Posting Date">
                                    <i><small *ngIf="form.controls['posting_date'].invalid" style="color: red;"> Mandatory</small></i>
                                </div>	 -->

                            <div class="form-group">
                                <label for="selectType">Leave Type</label>
                                <select formControlName="leave_type" id="selectType" class="form-control"
                                    name="leave_item_type">
                                    <option value="" disabled selected>-- Choose leaves type --</option>
                                    <option *ngFor="let card of eleaveCore.leaveTypes" value="{{card.value}}">
                                        {{card.value}}
                                    </option>
                                </select>
                                <i><small *ngIf="form.controls['leave_type'].invalid"
                                        style="color: red;">Mandatory</small></i>
                            </div>

                            <div class="form-group">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-6 nopadding">
                                            <label for="fromDate">From Date</label>
                                            <input formControlName="from_date" type="date" class="form-control" value=""
                                                id="fromDate" aria-describedby="dateHelp" placeholder="From Date">
                                            <!-- <label for="origin">Destination From</label>
                              <select formControlName="mileage_from" id="origin" class="form-control" name="origin">
                                <option value="" disabled selected>From</option>
                                <option *ngFor="let cards of locationList" value="{{cards.location}}">{{cards.location}}</option>
                              </select> -->
                                            <i><small *ngIf="form.controls['from_date'].invalid"
                                                    style="color: red;">Mandatory</small></i>
                                        </div>
                                        <div class="col-6 nopadding">
                                            <label for="toData">To Date</label>
                                            <input [(ngModel)]="toDate" [readonly]="!leaveTypeSelection()"
                                                formControlName="to_date" type="date" class="form-control"
                                                value="{form.controls.['from_date'].value:''}" id="toDate"
                                                aria-describedby="dateHelp" placeholder="To Date">
                                            <!-- <label for="destination">Destination To</label>
                              <select formControlName="mileage_to" id="destination" class="form-control">
                                <option value="" disabled selected>To</option>
                                <option *ngFor="let card of locationList" value="{{card.location}}">{{card.location}}</option>
                              </select> -->
                                            <i><small *ngIf="form.controls['to_date'].invalid"
                                                    style="color: red;">Mandatory</small></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="leaveBalanceShow" class="form-group">
                                <label for="leaveBalance">Leave Balance Table</label>
                                <div class="table-container">
                                    <table class="table table-bordered">
                                        <thead class="table-secondary">
                                            <tr class="text-center" style="font-size: smaller;">
                                                <th *ngFor="let header of leaveAlocationheaders; let i = index"
                                                    scope="col"
                                                    class="{{ i === 0 ? 'top-first' : '' }} {{ i === leaveAlocationheaders.length - 1 ? 'top-last' : '' }}">
                                                    {{header}}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="body-row text-center" style="font-size: smaller;">
                                                <th scope="row">
                                                    {{leaveAlocation.allocation_details[0].leave_type}}</th>
                                                <td>{{leaveAlocation.leaves_allocated}}</td>
                                                <td>{{leaveAlocation.carry_forward}}</td>
                                                <td>{{leaveAlocation.total_leaves}}</td>
                                                <td>{{leaveAlocation.leaves_taken}}</td>
                                                <td>{{leaveAlocation.carry_forward_taken}}</td>
                                                <td>{{leaveAlocation.expired_carry_forward}}</td>
                                                <td>{{leaveAlocation.remaining_leaves}}</td>
                                                <td>{{leaveAlocation.prorated_leaves}}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div *ngIf="leaveBalanceEmpty" class="form-group" style="color: red;">
                                <i><small>No leave allocated for the leave type selected</small></i>
                            </div>

                            <div class="form-group" *ngIf="leaveBalanceOn">
                                <label for="leaveBalance">Leave Balance Before Application</label>
                                <input [(ngModel)]="leaveBalance" value="{{leaveBalance}}"
                                    formControlName="leaveBalance" type="text" class="form-control" name="leaveBalance"
                                    id="leaveBalance" aria-describedby="distanceHelp" required="true" [readonly]="true">
                            </div>

                            <div class="form-group" *ngIf="totalLeaveDaysShow">
                                <label for="total_leave_days">Total Leave Days</label>
                                <input [(ngModel)]="total_leave_days" formControlName="total_leave_days" type="text"
                                    min="1" class="form-control" value="{form.controls.['total_leave_days'].value}"
                                    name="total_leave_days" id="total_leave_days" aria-describedby="distanceHelp"
                                    placeholder="{{form.value.total_leave_days}}" required="true"
                                    [readonly]="readOnlyTotalLeave">
                                <i><small *ngIf="form.controls['total_leave_days'].invalid || hoursMoreThan_4"
                                        style="color: red;">Hours more than 4 hours, select half day</small></i>
                            </div>

                            <!-- <div class="form-group" *ngIf="timeOffForm">
                        <label for="fromDate">From Date</label>
                        <input formControlName="from_date" type="date" class="form-control" value="" id="fromDate"
                          aria-describedby="dateHelp" placeholder="From Date">
                        <i><small *ngIf="form.controls['from_date'].invalid" style="color: red;"> Mandatory</small></i>
                      </div>
                      <div class="form-group" *ngIf="timeOffForm">
                        <label for="toData">To Date</label>
                        <input formControlName="to_date" type="date" class="form-control" value="" id="toDate"
                          aria-describedby="dateHelp" placeholder="To Date">
                        <i><small *ngIf="form.controls['to_date'].invalid" style="color: red;"> Mandatory</small></i>
                      </div> -->

                            <div class="form-group" *ngIf="!leaveTypeSelection()">
                                <label for="fromTime">From Time</label>
                                <input formControlName="from_time" type="time" class="form-control" value=""
                                    id="fromTime" aria-describedby="dateHelp" placeholder="From Time">
                                <i><small *ngIf="form.controls['from_time'].invalid" style="color: red;">
                                        Mandatory</small></i>
                            </div>

                            <div class="form-group" *ngIf="!leaveTypeSelection()">
                                <label for="toTime">To Time</label>
                                <input formControlName="to_time" type="time" class="form-control" value="" id="toTime"
                                    aria-describedby="dateHelp" placeholder="To Time">
                                <i><small *ngIf="form.controls['to_time'].invalid" style="color: red;">
                                        Mandatory</small></i>
                            </div>

                            <!-- checkbox for weekend -->

                            <div class="form-group" *ngIf="leaveTypeSelection()">
                                <label class="checkboxcontainer mb-0 pb-1 nopadding" for="include_weekend">Included
                                    Weekend

                                    <input [attr.disabled]="form.controls['to_date'].value === null? true : null ||
                                    form.controls['to_date'].value === ''? true : null"
                                        formControlName="include_weekend" type="checkbox" name="include_weekend"
                                        id="include_weekend" aria-describedby="include_weekend"
                                        style="padding-bottom: 10px;">
                                    <span class="checkmark"></span>
                                </label>
                                <div aria-required="true" class="single-search-inner content" style=""
                                    *ngIf="includedWeekend">
                                    <app-ngselect-weekend-select (selectedWeekendOutput)="selectedWeekend($event)"
                                        [selectedWeekend]="selectedWeekendTemp">
                                    </app-ngselect-weekend-select>
                                </div>
                                <i><small
                                        *ngIf="form.controls['include_weekend'].invalid || (selectedWeekendTemp.length === 0 && includedWeekend)"
                                        style="color: red;">
                                        Mandatory</small></i>
                            </div>


                            <!-- End of checkbox -->

                            <!-- checkbox for halfday -->

                            <div class="form-group pt-2">
                                <label class="checkboxcontainer mb-0 pb-1 nopadding" for="half_day">Half Day
                                    <input [attr.disabled]="form.controls['to_date'].value === null? true : null ||
                                    form.controls['to_date'].value === ''? true : null" formControlName="half_day"
                                        type="checkbox" name="half_day" id="half_day" aria-describedby="half_day"
                                        style="padding-bottom: 10px;">
                                    <span class="checkmark"></span>
                                </label>
                                <div aria-required="true" class="single-search-inner content" style=""
                                    *ngIf="HalfdayDropdownShow">
                                    <app-ngselect-halfday-select (selectedHalfdayOutput)="selectedHalfday($event)"
                                        [selectedHalfday]="selectedHalfdayTemp"></app-ngselect-halfday-select>
                                </div>
                                <i><small
                                        *ngIf="form.controls['half_day'].invalid || (selectedHalfdayTemp.length === 0 && HalfdayDropdownShow)"
                                        style="color: red;">
                                        Mandatory</small></i>
                            </div>


                            <div class="form-group" *ngIf="HalfdayInputShow">
                                <label for="toData">Half Day Date</label>
                                <input formControlName="half_day_date" type="date" class="form-control" value=""
                                    id="toDate" aria-describedby="dateHelp" placeholder="To Date" [readonly]="timeOff">
                                <i><small *ngIf="form.controls['half_day_date'].invalid" style="color: red;">
                                        Mandatory</small></i>
                            </div>

                            <!-- End of checkbox -->

                            <div class="form-group">
                                <label for="description">Reason</label>
                                <textarea required="true" [(ngModel)]="description" formControlName="description"
                                    class="form-control" id="description" rowspan="3"></textarea>
                                <i><small *ngIf="form.controls['description'].invalid"
                                        style="color: red;">Mandatory</small></i>
                            </div>

                            <div class="form-group">
                                <label for="proof">Attachment for leave</label>
                                <div *ngIf="!gotAttachment">
                                    <input type="file" class="form-control" value="" id="proof"
                                        aria-describedby="amountHelp" placeholder="Attachment for leave"
                                        accept="image/*, application/pdf" (change)="selectedFile($event)">
                                </div>
                                <div *ngIf="gotAttachment">
                                    <input type="text" value="{{selected_files['name']}}" readonly>
                                    <i (click)="removeContent()">Remove</i>
                                </div>
                                <i><small id="amountHelp" class="form-text text-muted"></small></i>
                            </div>

                            <div class="form-group" *ngIf="compassionateReasonForm">
                                <label for="compassionateReason">Compassionate Leave</label>
                                <!-- <textarea formControlName="compassionateReason" class="form-control" id="compassionateReason" rowspan="3"></textarea> -->
                                <select formControlName="compassionateReason" id="compassionateReason"
                                    class="form-control" name="compassionateReason">
                                    <option value="" disabled selected>-- Choose reason type --</option>
                                    <option *ngFor="let compReason of compassionateLeaveReasonList"
                                        value="{{compReason.compasionateReason}}">
                                        {{compReason.compasionateReason}}</option>
                                </select>
                                <i><small *ngIf="form.controls['compassionateReason'].invalid"
                                        style="color: red;">Mandatory</small></i>
                            </div>

                            <div class="row">
                                <div class="col-12 nopadding">
                                    <button [disabled]="!form.valid" type="submit"
                                        class="btn-c btn-blue w-100 btn-lg mt-4 mb-4"
                                        [ngClass]="{'btn-disabled': form.valid == false}">
                                        Save Leave
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>