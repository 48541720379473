import { Component, EventEmitter, OnInit, Output, Renderer2, Inject } from '@angular/core';
import { EleaveApproveService } from '../services/eleave-approve.service';
import { EleaveApproverChangeDetactionService } from 'fortunebit-staffhub/src/eleave/shared/eleave-approver-change-detaction.service';

@Component({
  selector: 'app-eleave-type-status',
  templateUrl: './eleave-type-status.component.html',
  styleUrls: ['./eleave-type-status.component.scss']
})
export class EleaveTypeStatusComponent implements OnInit {

  public count: number = 0
  @Output('selectedStatusOutput') public selectedStatusOutput = new EventEmitter<string>()
  constructor(
    @Inject(EleaveApproveService) private readonly eleaveApproveService: EleaveApproveService,
    @Inject(EleaveApproverChangeDetactionService) private readonly changeDetaction: EleaveApproverChangeDetactionService,
    @Inject(Renderer2) private readonly renderer2: Renderer2
  ) { }

  ngOnInit(): void {
    this.renderer2.setAttribute(document.querySelector(".status_dropdown"), "data-color", "All")
    // this.eleaveApproveService.setFilterStatus("All")
  }

  selectedValue(event) {
    // console.log(event, "events")
    const statusDropdown = document.querySelector(".status_dropdown");
    const statusButton = statusDropdown.querySelector(".status__btn");
    const dropdownItems = statusDropdown.querySelectorAll(".dropdown-item");
    if (event.returnValue) {
      this.changeDetaction.setStatusChange(event.target.innerText)
      this.renderer2.setAttribute(statusDropdown, "data-color", event.target.innerText)
      this.renderer2.setProperty(statusButton, 'textContent', event.target.innerText);
    }
  }



}
