import { Inject, Injectable, OnInit } from '@angular/core'
import { GlobalVariables } from 'fortunebit-staffhub/src/libs'
import { CreateClaimPage } from 'desktop-app/src/app/eclaim/create-claim/create-claim.page'
import { ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator'
import { ModalService } from '../../core/modal.service'
import { ExpenseClaimApi } from './eclaim-api'
import * as eclaimTypes from './typings/eclaim-api.typings' // Get everything for expense claim type
import { LoaderService } from '@desktop-common-comp/services/loader.service'
import { ExpenseClaim, ExpenseClaimDetail } from 'fortunebit-staffhub/src/model/expense_claim.model'
import { validate } from 'class-validator'
import { ToastService } from '@desktop-common-comp/services/toast.service'
import { Router } from '@angular/router'
import { ChangeDetectionService } from 'desktop-app/src/app/eclaim/shared/change-detection.service'
import { attachement } from 'fortunebit-staffhub/src/typings/common-api.typings'

/** The purpose of this script it to provide all the nessacary ExpenseClaim Functions without 
exposing underlying api's
Can carry out input and output validation on this script.
*/
@Injectable({
    providedIn: 'root'
})
export class ExpenseClaimCore {
    public ExpenseList: any = []
    constructor(
        @Inject(ChangeDetectionService) private changeDetection: ChangeDetectionService,
        @Inject(ExpenseClaimApi) public ExpenseClaimApi: ExpenseClaimApi,
        @Inject(ModalService) private modalService: ModalService,
        @Inject(ExpenseClaimApi) private eclaimAPI: ExpenseClaimApi,
        @Inject(GlobalVariables) private global: GlobalVariables,
        @Inject(LoaderService) private loader: LoaderService,
        @Inject(ToastService) public toast: ToastService,
        @Inject(Router) private router: Router,
    ) {
        this.fetchExpenseList()
    }


    // As of rite now we maintain the return type as promises
    getClaimList(args: eclaimTypes.InputClaimList): Promise<Array<eclaimTypes.ExpensesClaimData>> {
        // You can provide validation section before hiting the api calls 


        return new Promise((resolve, reject) => {
            this.ExpenseClaimApi.getClaimList(args).subscribe(
                res => { resolve(res) },
                err => { reject(err) } // Error can be handled here using rxjs operators or our own custom operators
            )
        })
    }


    modalEclaimCreate() {
        this.modalService.createComponentAppWide().then(compRef => {
            console.log(compRef, 'compRef');
            compRef.instance.title = "Add Expenses"
            compRef.instance.component = CreateClaimPage
            compRef.instance.size = 'full'
            compRef.instance.dismiss.subscribe(data => {
                compRef.destroy()
                compRef.changeDetectorRef.detectChanges()
            })
            setTimeout(() => {
                compRef.changeDetectorRef.detectChanges()
            }, 150)
            compRef.instance.submitted.subscribe(data => {
                compRef.destroy()
                this.changeDetection.triggerClaimList()
                compRef.changeDetectorRef.detectChanges()
            })
        })
    }


    getFromToList() {
        return [
            { "location": "Manjung Office", "distance": 321 },
            { "location": "Ayer Tawar", "distance": 100 },
            { "location": "Perak Tengah", "distance": 150 },
            { "location": "Seri Iskandar", "distance": 200 },
            { "location": "Batu Gajah", "distance": 100 },
            { "location": "Station 18", "distance": 76 },
            { "location": "Ipoh", "distance": 80 },
            { "location": "Bukit Mertajam", "distance": 45 },
            { "location": "Penang", "distance": 87 },
            { "location": "KL Office", "distance": 95 },
            { "location": "Bukit Bintang", "distance": 23 },
            { "location": "Shah Alam", "distance": 45 },
            { "location": "Cheras", "distance": 78 },
            { "location": "Ampang", "distance": 98 },
            { "location": "Putrajaya", "distance": 23 },
            { "location": "Senawang", "distance": 87 },
            { "location": "Teluk Intan", "distance": 23 },
            { "location": "Taiping", "distance": 56 },
            { "location": "Others", "distance": 78 }
        ]
    }


    determineMileageDistance(form: ClassValidatorFormGroup) {
        // Assume validation of milleage already happened
        if (form.controls['mileage_to'].value != 'Others' && form.controls['mileage_from'].value != 'Others') {
            this.eclaimAPI.getMillageAmount({
                company: this.global.user_global.company,
                employee: this.global.user_global.employee_id,
                from_distance: form.controls['mileage_from'].value,
                return_trip: form.controls['mileage_return'].value,
                to_distance: form.controls['mileage_to'].value
            }).subscribe(data => {
                form.patchValue({
                    mileage_description: `${form.controls['mileage_from'].value} \n ${form.controls['mileage_to'].value}`,
                    mileage_from: form.controls['mileage_from'].value,
                    mileage_to: form.controls['mileage_to'].value,
                    amount: data['message'].final_claim_amount,
                    mileage_distance: data['message'].total_distance,
                    transport_allowance: data['message'].total_allowance,
                    deducted_transport_allowance: data['message'].total_consumed_on_doc
                }, { emitEvent: false })
            })
        }
    }

    async determineOtherMileageDistance(form: ClassValidatorFormGroup) {
        // Assume validation of milleage already happened
        if (form.controls['mileage_to'].value == 'Others' || form.controls['mileage_from'].value == 'Others') {
            this.eclaimAPI.getMillageOtherAmount({
                total_distance: form.controls.mileage_distance.value,
                company: this.global.user_global.company,
                employee: this.global.user_global.employee_id,
                return_trip: form.controls.mileage_return.value,
            }).subscribe(data => {
                console.log({ data });

                form.patchValue({
                    mileage_description: ` ${form.controls.mileage_from.value} \n ${form.controls.mileage_to.value}`,
                    mileage_from: form.controls.mileage_from.value,
                    mileage_to: form.controls.mileage_to.value,
                    amount: data['message'].final_claim_amount,
                    mileage_distance: data['message'].total_distance,
                    transport_allowance: data['message'].total_allowance,
                    deducted_transport_allowance: data['message'].total_consumed_on_doc
                }, { emitEvent: false })
            })
        }
    }

    fetchExpenseList() {
        this.eclaimAPI.getExpenseList().subscribe(data => {
            this.ExpenseList = data['results']
        })
    }


    async submitClaim(form: ClassValidatorFormGroup, attachement: attachement | null = null) {
        return new Promise(async (resolve, reject) => {

            await this.loader.presentGlobalLoading()
            let parent_expense = new ExpenseClaim()
            let new_expense = new ExpenseClaimDetail()

            Object.assign(new_expense, form)
            parent_expense.employee = this.global.user_global.employee_id
            new_expense.disclaimed_transport_allowance = new_expense.transport_allowance
            parent_expense.expenses = [new_expense]

            await validate(parent_expense).then(async (data) => {
                // Validated
            }).catch(error => {
                // Unvalidated
                console.log(error)
            })

            await this.eclaimAPI.createExpenseClaim(parent_expense).subscribe(data => {
                this.toast.successToast('Succesfully Create Claim', 'success')
                this.loader.hideGlobalLoading()
                resolve(data)
            }, error => {
                console.log(error);
                this.toast.errorToast('Error Create Claim. Please try again later', 'Error')
                this.loader.hideGlobalLoading()
                reject(error)
            })

            // console.log(parent_expense, 'form');
        })
    }

    upload_attachement(attachement: attachement, docname: string) {
        return new Promise(async (resolve, reject) => {
            console.log(attachement)
            console.log(docname)
            await this.loader.presentGlobalLoading()
            await this.eclaimAPI.ext_setExpenseClaimAttachment({
                'attachement': attachement,
                'docname': docname
            }).toPromise().then(data => {
                this.toast.successToast('Attachement Uploaded Succesfully', 'success')
                this.loader.hideGlobalLoading()
                resolve(true)
            }).catch(error => {
                console.log(error)
                this.toast.errorToast('Error Uploading Attachment. Please try again later', 'Error')
                this.loader.hideGlobalLoading()
                reject(error)
            })
        })
    }


    async updateClaim(form: ClassValidatorFormGroup, child_doc: ExpenseClaimDetail, parent_doc_name: string, attachement: attachement | null = null) {
        return new Promise(async (resolve, reject) => {
            await this.loader.presentGlobalLoading()
            let parent_doc = new ExpenseClaim()

            // // Change reference of transport allowance - temporary solution

            // // Merge value's from form to class
            Object.assign(child_doc, form)
            // if (form.contains('mileage_to') || form.contains('mileage_from')) {
            //     child_doc.disclaimed_transport_allowance = form.value.transport_allowance
            // }
            console.log(child_doc)
            // child_doc.attachment = null
            parent_doc.action = 'Update'
            child_doc.action = 'Update'
            parent_doc.name = parent_doc_name
            parent_doc.expenses = [child_doc]
            parent_doc.action = 'Update'

            await this.eclaimAPI.updateExpenseClaim(parent_doc).subscribe(data => {
                this.toast.successToast('Claim Succesfully Updated', 'success')
                this.loader.hideGlobalLoading()
                resolve(true)
            }, error => {
                console.log(error)
                this.toast.errorToast('Error Updating claim. Please try again later', 'Error')
                this.loader.hideGlobalLoading()
                reject(error)
            })
        })

    }

    delete_attachment(attachement: attachement, docname: string) {
        return new Promise(async (resolve, reject) => {
            // let load = await this.loader.presentLoading('Deleting Attachment')

            await this.eclaimAPI.ext_deleteExpenseClaimAttachment({
                'attachement': attachement,
                'docname': docname
            }).toPromise().then(data => {
                this.toast.successToast('Attachement Deleted Succesfully', 'success')
                // load.dismiss()
                resolve(true)
            }).catch(error => {
                this.toast.errorToast('Error Delete Attachment. Please try again later', 'Error')
                // load.dismiss()
                reject(error)
            })
        })
    }

}