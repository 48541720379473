import { Component, OnInit, Inject } from '@angular/core';
import { SideMenuEmitService } from 'fortunebit-staffhub/src/core/global-emitters/glob-side-menu.emit.services'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    constructor(
        @Inject('SIDEMENUGLOBAL') private sideMenuService: SideMenuEmitService
    ) { }

    ngOnInit(): void { }

    activateMenu() {
        // Does not store state, So just emit random event
        this.sideMenuService.emitEvent(true)
    }
}
