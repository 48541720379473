import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { cs_log } from "@handler/logger.handler";
import * as moment from 'moment';
import { Subject } from 'rxjs';


interface ToFromDate {
  from_date: Moment
  to_date: Moment
}

@Injectable({
  providedIn: 'root'
})
export class ToFromDateService {

  public from_date: Moment
  public to_date: Moment

  public ToFromDateListner = new Subject<ToFromDate>()

  constructor() {
    cs_log(this, 'ToFromDate log')
    this.setDates()
  }

  setDates() {
    this.from_date = moment().subtract(1, 'months')
    this.to_date = moment()
  }

  getDates() {
    return {
      'from_date': this.from_date,
      'to_date': this.to_date
    }
  }

  getDatesChanges(f_date: Moment, t_date: Moment) {
    this.from_date = f_date
    this.to_date = t_date
    this.ToFromDateListner.next(this.getDates())
  }

  resetDates(refresh: Boolean = false) {
    this.setDates()
    if (refresh) {
      this.ToFromDateListner.next(this.getDates())
    }
  }


}
