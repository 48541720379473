import { EventEmitter, Inject, Injectable } from "@angular/core";
import { LoaderService } from "@desktop-common-comp/services/loader.service";
import { ToastService } from "@desktop-common-comp/services/toast.service";
import { validate } from "class-validator";
import * as eleaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'
import { CreateLeavePage } from "desktop-app/src/app/eleave/create-leave/create-leave.page";
import { GlobalVariables } from "fortunebit-staffhub/src/libs";
import { Eleave } from "fortunebit-staffhub/src/model/eleave.model";
import { ClassValidatorFormGroup } from "ngx-reactive-form-class-validator";
import { ModalService } from "../../core/modal.service";
import { ExpenseEleaveApi } from "./eleave-api";
import { EleaveLeaveBalanceOnArgs, attachement } from "./typings/eleave-api.typings";
import { HttpErrorResponse } from "@angular/common/http";
import { EleaveListingService } from "desktop-app/src/app/eleave/eleave-listing/services/eleave-listing.service";

export interface Weekend {
    id: number,
    description: string,
    text: string,
}

export interface Halfday {
    id: number,
    text: string,
    halfdays: string,
}


@Injectable({
    providedIn: 'root'
})
export class EleaveCore {

    public leaveTypes: any = []


    constructor(
        @Inject(ToastService) public toast: ToastService,
        @Inject(LoaderService) private loader: LoaderService,
        @Inject(ModalService) private modalService: ModalService,
        @Inject(GlobalVariables) private global: GlobalVariables,
        @Inject(ExpenseEleaveApi) private eleaveAPI: ExpenseEleaveApi,
    ) {
        this.getLeaveTypeSelect()
    }

    modalEleaveCreate() {
        this.modalService.createComponentAppWide().then(compRef => {
            compRef.instance.component = CreateLeavePage
            compRef.instance.size = 'full'
            compRef.instance.dismiss.subscribe(data => {
                compRef.destroy()
                compRef.changeDetectorRef.detectChanges()
            })
            setTimeout(() => {
                compRef.changeDetectorRef.detectChanges()
            }, 150)
            compRef.instance.submitted.subscribe(data => {
                compRef.destroy()
                // this.changeDetection.triggerClaimList()
                compRef.changeDetectorRef.detectChanges()
            })
        })
    }

    getLeaveTypeSelect() {
        return this.eleaveAPI.getLeaveTypeList().subscribe(data => {
            this.leaveTypes = data['results']
        })
    }

    displayWeekendDates = new EventEmitter<Weekend[]>()
    setDates(Weekend: Weekend[]) {
        this.displayWeekendDates.emit(Weekend)
    }

    displayHalfdayDates = new EventEmitter<Halfday[]>()
    setHalfdayDates(Halfday: Halfday[]) {
        this.displayHalfdayDates.emit(Halfday)
    }

    async determineNumberOfLeaveDays(form: ClassValidatorFormGroup) {
        if (form.controls['from_date'].value != undefined && form.controls['to_date'].value != undefined) {
            this.eleaveAPI.getNumberOfLeaveDays({
                employee: this.global.user_global.employee_id,
                leave_type: form.controls['leave_type'].value,
                from_date: form.controls['from_date'].value,
                to_date: form.controls['to_date'].value,
                include_weekend: form.controls['include_weekend'].value,
                weekend_dates: form.controls['select_weekend'].value,
                half_day: form.controls['half_day'].value,
                half_day_dates: form.controls['half_day_date_jq_data'].value,
            }).subscribe(data => {
                form.patchValue({
                    leave_type: form.controls['leave_type'].value,
                    from_date: form.controls['from_date'].value,
                    to_date: form.controls['to_date'].value,
                    total_leave_days: data['message']
                }, { emitEvent: false })
            })
        }
    }


    determineLeaveWeekendList(form: ClassValidatorFormGroup) {
        if (form.controls['from_date'].value != undefined && form.controls['to_date'].value != undefined) {
            this.eleaveAPI.getLeaveWeekendList({
                from_date: form.controls['from_date'].value,
                to_date: form.controls['to_date'].value,
                employee: this.global.user_global.employee_id
            }).subscribe(data => {
                this.setDates(data['message'])
            })
        }
    }

    determineHalfdayDatesList(form: ClassValidatorFormGroup) {
        if (form.controls['from_date'].value != undefined && form.controls['to_date'].value != undefined) {
            this.eleaveAPI.getHalfdayDatesList({
                from_date: form.controls['from_date'].value,
                to_date: form.controls['to_date'].value,
            }).subscribe(data => {
                this.setHalfdayDates(data['message'])
            })
        }
    }

    getLeaveDetail(documentid) {
        return new Promise<Eleave>((resolve, reject) => {
            this.eleaveAPI.getLeaveApplicationDetails({
                name: documentid
            }).subscribe(data => {
                resolve(data['message'])
            }, (error) => {
                this.toast.successToast('Document Not Found', 'success')
                reject(true)
            })
        })
    }

    async submitEleave(form) {
        return new Promise(async (resolve, reject) => {
            await this.loader.presentGlobalLoading()

            let new_leave = new Eleave()

            Object.assign(new_leave, form)

            let user = this.global.user_global
            new_leave.employeee = user.employee_id
            new_leave.employee_no = user.employee_id
            new_leave.employee_name = user.employee_name
            new_leave.company = user.company
            new_leave.leave_approver_id = user.reports_to_id
            new_leave.leave_approver_name = user.reports_to_name

            await validate(new_leave).then(async (data) => {
                // Validated

            }).catch(error => {
                // Unvalidated
                console.log(error, 'validate error')
            })

            // console.log({ new_leave });


            await this.eleaveAPI.setLeaveApplication(new_leave).subscribe(data => {
                this.toast.successToast('Succesfully Create Leave', 'success')
                this.loader.hideGlobalLoading()
                resolve(data)
            }, error => {
                console.log(error);
                this.loader.hideGlobalLoading()
                reject(error)
            })
        })

    }


    async updateLeave(form) {
        return new Promise(async (resolve, reject) => {
            await this.loader.presentGlobalLoading()

            let new_leave = new Eleave()

            // Merge value's from form to class
            Object.assign(new_leave, form)

            // Append Employee Information
            // let user = this.core.getEmployee()

            // Validate
            await validate(new_leave).then(async (data) => {
                // Validated
            }).catch(error => {
                // Unvalidated
                console.log(error)
            })

            // console.log({ new_leave });

            await this.eleaveAPI.updateLeaveApplication(new_leave).subscribe(data => {
                this.toast.successToast('Leave Succesfully Updated', 'success')
                this.loader.hideGlobalLoading()
                resolve(data)
            }, error => {
                // console.log({ error });
                this.toast.errorToast(error?.error?.message, 'error')
                this.loader.hideGlobalLoading()
                reject(error)
            })
        })

    }

    async upload_attachement(attachement: attachement, docname: string) {
        return new Promise(async (resolve, reject) => {
            await this.loader.presentGlobalLoading()
            console.log(attachement)
            console.log(docname)
            await this.eleaveAPI.uploadEleaveAttachment({
                'attachement': attachement,
                'docname': docname
            }).toPromise().then(data => {
                this.loader.hideGlobalLoading()
                this.toast.successToast('Success', 'Attachement Uploaded Succesfully')
                resolve(true)
            }).catch((error: HttpErrorResponse) => {
                console.log(error)
                if (error.error['code'] == '400') {
                    this.toast.errorToast('Server Error!', `Error Uploading Attachment! ${error.error['message']}`)
                } else {
                    this.toast.errorToast('Warning!', `Validation Error! ${error.error['message']}`)
                }
                reject(error)
            })
        })
    }

    async delete_attachment(attachement: attachement, docname: string) {
        return new Promise(async (resolve, reject) => {
            await this.loader.presentGlobalLoading()
            await this.eleaveAPI.deleteEleaveAttachment({
                'attachement': attachement,
                'docname': docname
            }).toPromise().then(data => {
                this.toast.successToast('Success', 'Attachement Deleted Succesfully')
                this.loader.hideGlobalLoading()
                resolve(true)
            }).catch((error: HttpErrorResponse) => {
                if (error.error['code'] == '400') {
                    this.toast.errorToast('Server Error!', `Error Deleting Attachment! ${error.error['message']}`)
                } else {
                    this.toast.errorToast('Warning!', `Validation Error! ${error.error['message']}`)
                }
                reject(error)
            })
        })
    }


    async submitLeaveApproval(form: ClassValidatorFormGroup) {
        return new Promise(async (resolve, reject) => {
            await this.loader.presentGlobalLoading()

            let new_leave = new Eleave()

            // Merge value's from form to class
            Object.assign(new_leave, form.value)

            // Append Employee Information
            new_leave.action = 'Submit for Approval'

            // Validate
            await validate(new_leave).then(async (data) => {
                // Validated
            }).catch(error => {
                // Unvalidated
                console.log(error)
            })

            await this.eleaveAPI.updateLeaveApplication(new_leave).subscribe(data => {
                this.toast.successToast('Leave Succesfully Submitted For Approval', 'success')
                this.loader.hideGlobalLoading()
                resolve(data)
            }, error => {
                this.toast.errorToast(`${error?.error?.message}`, 'error')
                this.loader.hideGlobalLoading()
                reject(error)
            })
        })

    }


    async removeLeave(form: ClassValidatorFormGroup) {
        return new Promise(async (resolve, reject) => {
            await this.loader.presentGlobalLoading()

            let new_leave = new Eleave()

            // Merge value's from form to class
            Object.assign(new_leave, form.value)

            // Append Employee Information
            new_leave.action = 'Remove'

            // Validate
            await validate(new_leave).then(async (data) => {
                // Validated
            }).catch(error => {
                // Unvalidated
                console.log(error)
            })

            await this.eleaveAPI.updateLeaveApplication(new_leave).subscribe(data => {
                this.toast.successToast('Leave Succesfully Removed', 'success')
                this.loader.hideGlobalLoading()
                resolve(data)
            }, error => {
                this.toast.successToast(`${error.error['message']}`, 'success')
                this.loader.hideGlobalLoading()
                reject(false)
            })
        })

    }

    leaveListing(args: eleaveTypes.EleaveLisingFilterMeta) {
        return new Promise((resolve, reject) => {
            this.eleaveAPI.getLeaveApplicationList(args).subscribe(data => {
                if (data['message']) {
                    resolve(data['message'])
                    //   this.leaveListSkeleton$.next(false)
                }
            }, err => {
                reject(err)
            })
        })
    }


    getLeaveBalanceOn(args: EleaveLeaveBalanceOnArgs) {
        // console.log({ args });
        return new Promise((resolve, reject) => {
            this.eleaveAPI.getLeaveBalanceOn(args).subscribe(data => {
                resolve(data['message'])
            }, err => {
                reject(err)
            })
        }).catch(err => {
            console.log(err);
        })
    }







}