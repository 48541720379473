import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { EleaveCore } from 'fortunebit-staffhub/src/eleave/services/eleave-core';
import { Observable, Subject } from 'rxjs';

export interface LeaveType {
  value: string,
  label: string,
  metadata: NgOption
}

@Component({
  selector: 'app-ngselect-leave-type-select',
  templateUrl: './ngselect-leave-type-select.component.html',
  styleUrls: ['./ngselect-leave-type-select.component.scss']
})
export class NgselectLeaveTypeSelectComponent implements OnInit, AfterViewInit {

  leavetype$: Observable<LeaveType[]>
  leavetypeLoading = false
  leavetypeInput$ = new Subject<string>()
  newlyAdded: LeaveType[] = [
    // { value: "All", label: "All", metadata: [] },
    { value: "Annual Leave", label: "Annual Leave", metadata: [] },
    { value: "Annual Leave-R", label: "Annual Leave (Replacement)", metadata: [] },
    // { value: "Compensatory Leave", label: "Compensatory Leave", metadata: [] },
    { value: "Medical Leave", label: "Medical Leave", metadata: [] },
    // { value: "Hospitalization Leave", label: "Hospitalization Leave", metadata: [] },
  ]

  public default: LeaveType

  @Input('selectedLeaveType') public selectedLeaveType: LeaveType
  @Input('replaceSelected') public replaceSelected = new EventEmitter<LeaveType>()
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedLeaveTypeOutput') public selectedLeaveTypeOutput = new EventEmitter<LeaveType>()
  @ViewChild("leavetypeSelect") public leavetypeSelect: NgSelectComponent


  constructor(
    @Inject(EleaveCore) public eleaveCore: EleaveCore
  ) {
    // this.loadYear()
  }


  ngOnInit() {
    // console.log(this.selectedLeaveTypeOutput)
  }

  ngAfterViewInit(): void {
    this.trackLeaveTypeSelected()
    this.removeSelectedType()
    this.replaceSelectedType()
    this.default = { value: "Annual Leave", label: "Annual Leave", metadata: [] }
  }


  replaceSelectedType() {
    this.replaceSelected.subscribe((replaceSelected) => {
      // Clear all selected employee
      this.leavetypeSelect.itemsList.clearSelected()

      replaceSelected

      replaceSelected.metadata.selected = false
      this.leavetypeSelect.itemsList.select(replaceSelected.metadata)

      this.leavetypeSelect.detectChanges()
      // Attach new selected items

    })
  }

  // removeSelectedUser() {
  //   this.removeSelected.subscribe((dataSelected: string) => {
  //     // check if selected user exist
  //     this.employeeSelect.itemsList.selectedItems.forEach(data => {
  //       if (data.value.value == dataSelected) {
  //         this.employeeSelect.itemsList.unselect(data)
  //         this.employeeSelect.detectChanges()
  //         // Send latest employee detail after removal
  //         this.selectedEmployeeOutput.emit(this.employeeSelect.selectedValues)
  //       }
  //     })
  //   })
  // }

  removeSelectedType() {
    this.removeSelected.subscribe((dataSelected: string) => {
      // check if selected user exist
      this.leavetypeSelect.itemsList.selectedItems
      if (this.leavetypeSelect.itemsList.markedItem.value == dataSelected) {
        this.leavetypeSelect.itemsList.unselect(this.leavetypeSelect.itemsList.markedItem)
        this.leavetypeSelect.detectChanges()
        // Send latest employee detail after removal
        this.selectedLeaveTypeOutput.emit(this.leavetypeSelect.itemsList.markedItem.value)
      }
    })
  }

  private loadYear() {
    // this.eleaveCore.displayYearDates.subscribe((data) => {
    //   this.newlyAdded = data
    // })
  }

  dataInterceptor(data: LeaveType) {
    data.metadata = this.leavetypeSelect.itemsList.selectedItems.find((val) => {
      if (val.value['value'] == data.value) {
        return true
      }
      return false
    })
    return data
  }

  trackLeaveTypeSelected() {
    this.leavetypeSelect.changeEvent
      .subscribe(data => {
        // console.log({ data })
        this.selectedLeaveTypeOutput.emit(this.dataInterceptor(data))
      })
  }


}
