<table class="table border table-hover">
    <thead>
        <tr>
            <th class="type-tl-radius tbheader" scope="row">Leave Type</th>
            <th class="tbheader" scope="col">Entitlement</th>
            <th class="tbheader" scope="col">Leaves Allocated/YTD Prorate</th>
            <th class="tbheader" scope="col">Used Leaves</th>
            <th class="tbheader" scope="col">Expired Leaves</th>
            <th class="tbheader" scope="col">Remaining Leaves</th>
            <th class="type-tr-radius tbheader" scope="col">As at Prorate</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngIf="!isLeaveDetails">
            <th class="tbdata" [ngClass]="{'type-bl-radius': isCarryForward === false}" scope="row">No Allocation Found
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
        </tr>
        <tr *ngIf="isLeaveDetails">
            <th class="tbdata" [ngClass]="{'type-bl-radius': isCarryForward === false}" scope="row">
                {{leaveDetails['leave_type']}}</th>
            <th class="tbdata">{{leaveDetails['entitlement']}}</th>
            <th class="tbdata">{{leaveDetails['leaves_allocated']}}</th>
            <th class="tbdata">{{leaveDetails['leaves_taken']}}</th>
            <th class="tbdata">{{leaveDetails['expired_leaves']}}</th>
            <th class="tbdata">{{leaveDetails['remaining_leaves']}}</th>
            <th class="tbdata" [ngClass]="{'type-br-radius': isCarryForward === false}">
                {{leaveDetails['prorated_leaves']}}</th>
        </tr>
        <tr *ngIf="isCarryForward">
            <th class="tbCfdata type-bl-radius" scope="row">{{carryForwardDetails['leave_type']}}</th>
            <th class="tbdata">Uncalculated</th>
            <th class="tbCfdata">{{carryForwardDetails['carry_foward']}}</th>
            <th class="tbCfdata">{{carryForwardDetails['carry_forward_taken']}}</th>
            <th class="tbCfdata">{{carryForwardDetails['expired_carry_forward']}}</th>
            <th class="tbdata">Uncalculated</th>
            <th class="tbCfdata type-br-radius">Uncalculated</th>
        </tr>
    </tbody>
</table>