import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariables } from './globalvar.service'
import { environment } from '../env/environment'
import { LogOutService } from './logout.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {
  apiUrl: string = environment['apiUrl']
  hostUrl: string = environment['hostUrl']
  access_token: any
  headers: any = {}
  subscriptionStorage: {
    [key: string]: Subscription
  } = {}
  errorSession = new Subject()

  constructor(private http: HttpClient, private globalvar: GlobalVariables, private logOut: LogOutService) {
    // this.prepareHeader()
    this.errorSession.pipe(
      debounceTime(1500)
    ).subscribe((data) => {
      this.logOut.logOutSessionExpired()
    })
  }

  prepareHeader() {
    this.headers = {
      headers: { 'Authorization': `Bearer ${this.globalvar.auth_global.access_token}` },
      params: {}
    }
  }

  postDirect(url, body = {}) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + url, body).subscribe(data => {
        resolve(data)
        // console.log(data)
      }, error => {
        // this.terminate_session(error, reject)
        console.log(error)
      })
    })
  }

  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      this.prepareHeader()
      if (Object.keys(params).length > 0) {
        this.headers.params = params
      }

      this.http.get(this.apiUrl + url, this.headers).subscribe(data => {
        resolve(data)
      }, error => {
        this.terminate_session(error, reject)
      })
    })
  }

  post(url, body = {}, params = {}) {
    return new Promise((resolve, reject) => {
      this.prepareHeader()
      if (Object.keys(params).length > 0) {
        this.headers.params = params
      }

      this.http.post(this.apiUrl + url, body, this.headers).subscribe(data => {
        resolve(data)
      }, error => {
        this.terminate_session(error, reject)
      })
    })
  }

  postTemp(url, body = {}, params = {}, storageName = '', success: Subject<any>, error: Subject<any>) {
    this.subscriptionStorage[storageName] = this.http.post(this.apiUrl + url, body, this.headers).subscribe(data => {
      success.next(data)
    }, err => {
      error.next(err)
    })
    this.subscriptionStorage[storageName].add(() => {
      success.complete()
      error.complete()
      delete this.subscriptionStorage[storageName]
    })
  }

  postAfterCancel(url, body = {}, params = {}, storageName = null, cancelData = null) {
    return new Promise((resolve, reject) => {
      this.prepareHeader()
      if (Object.keys(params).length > 0) {
        this.headers.params = params
      }
      this.destroySubscription(storageName)
      let errorMessage = new Subject()
      let succesMessage = new Subject()
      errorMessage.subscribe(data => {
        this.terminate_session(data, reject)
      })
      succesMessage.subscribe(data => {
        resolve(data)
      }).add(() => {
        resolve(cancelData)
      })
      this.postTemp(url, body, params, storageName, succesMessage, errorMessage)
    })
  }

  destroySubscription(storageName) {
    console.log(this.subscriptionStorage)
    if (storageName in this.subscriptionStorage) {
      console.log(`Destorying ${storageName}`)
      this.subscriptionStorage[storageName].unsubscribe()
      // delete this.subscriptionStorage[storageName]
    }
  }

  terminate_session(error, reject) {
    if (error.code == 508) {
      console.log('session is invalid')
    } else {
      reject(error)
    }
  }
}
