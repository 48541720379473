<div [ngClass]="{'ba-navbar-show': state == true}" class="ba-navbar"
  style="display:inline-block; overflow: auto;  top:65px; width:250px; border-right:1px solid #eee; height:100%; box-shadow:0px 0px 55px rgba(0 0 0 / 10%)">
  <div class="ba-navbar-user text-center">
    <div class="menu-close" (click)="setState()">

      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42-.39-.39-1.02-.39-1.41 0l-6.59 6.59c-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z">
        </path>
      </svg>
    </div>
    <div class="thumb">
      <img src="./assets/img/user.png" alt="user">
    </div>
    <div class="details">
      <div style="margin: 0px 0px 15px 0px;">
        <h5>{{loggedInUserData.employee_name}}</h5>
        <p>ID: {{loggedInUserData.employee_id}}</p>
      </div>
      <span class="tag styleit">{{loggedInUserData.user_role}} - {{loggedInUserData.company}}</span>
      <span class="tag styleit">Report to: <b>{{loggedInUserData.reports_to_name}}</b></span>
    </div>
  </div>
  <div class="ba-balance-inner mb-3 text-end align-self-center none">
    <div class="icon">
      <img src="./assets/img/1.png" alt="img">
    </div>
    <h5 class="title">My Claim</h5>
    <h5 class="amount">RM56,985.00</h5>
  </div>
  <div class="ba-main-menu">
    <h5>Menu</h5>
    <ul>
      <li><a class="active" href="#">Dashboard</a></li>
      <li><a class="mt-2" href="/">Profile</a></li>
      <li><a class="mt-2 modules" style="cursor: pointer;" (click)="openListing()">Listing
        </a></li>
      <ul *ngIf="moduleList" class="mt-4" style="margin-left: 25px;">
        <li class="mt-2"><a href="/eclaim/listing"><b>E-Claim Listing</b></a></li>
        <!-- <li class="mt-2"><a href="/eleave/listing"><b>E-Leave Listing</b></a></li> -->
        <!-- <li class="mt-2"><a href="/eattendance/listing"><b>E-Attendance Listing</b></a></li> -->
      </ul>
      <li><a class="mt-2 modules" style="cursor: pointer;" (click)="openApproval()">Approval
        </a></li>
      <ul *ngIf="moduleApproval" class="mt-4" style="margin-left: 25px;">
        <li class="mt-2"><a href="/eclaim/approval"><b>E-Claim Approval</b></a></li>
        <li class="mt-2"><a href="/eleave/approval"><b>E-Leave Approval</b></a></li>
        <!-- <li class="mt-2"><a href="/eattendance/approval"><b>E-Attendance Approval</b></a></li> -->
      </ul>
      <li><a class="mt-2" href="#">Contact Support</a></li>
      <li><a class="mt-2" href="#">Report Bug</a></li>
      <li><a class="mt-6 text-pending" style="cursor: pointer;" (click)="logout()">Logout</a></li>
    </ul>
  </div>
</div>


<!--<li><a class="" href="">Notification</a></li>                -->