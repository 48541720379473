<!-- <div class="row" style="padding:12px 0px">
    <ion-progress-bar type="determinate" color="tertiary"  [value]="progress" min="0" ></ion-progress-bar>
</div> -->

<div>
    <!-- <h5 style="color: rgb(0, 0, 0);">Leaves Allocation</h5> -->
    <div class="card card-bar" style="margin: 0px;padding: 0px 20px 0px 5px;border-radius: 20px;background:unset;">
        <div class="chart-container">
            <canvas  id="MyChart" >{{ pie }}</canvas>
        </div>
    </div>
</div>
