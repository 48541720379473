import { Injectable, ViewContainerRef, ComponentRef, EventEmitter } from '@angular/core';
import { DesktopModalComponent } from '@desktop-common-comp/components/desktop-modal/desktop-modal.component'

export interface ModalOption {
    state: boolean
    title: string
    size: string
    component: ComponentRef<any>
    style?: string
    dismiss: EventEmitter<string>
    openClose: EventEmitter<boolean>
    submit: EventEmitter<string>
}

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    public instance: ComponentRef<DesktopModalComponent>
    public parentViewRef: ViewContainerRef
    constructor() {
        console.log('Modal Initiated')
    }

    createComponent(viewChildRef: ViewContainerRef): Promise<ComponentRef<DesktopModalComponent>> {
        return new Promise((resolve, reject) => {
            try {
                resolve(viewChildRef.createComponent(DesktopModalComponent))
            } catch (error) {
                reject('ViewRef Not Started')
            }
        })
    }

    getComponent() {
        return this.instance
    }

    setRootParentViewRef(viewChildRef: ViewContainerRef) {
        this.parentViewRef = viewChildRef
    }

    createComponentAppWide(): Promise<ComponentRef<DesktopModalComponent>> {
        return new Promise((resolve, reject) => {
            if (this.parentViewRef) {
                this.instance = this.parentViewRef.createComponent(DesktopModalComponent)
                this.instance.instance.state = true
                resolve(this.instance)
            } else {
                reject('ViewRef not declared')
            }
        })
    }

    clearRootViewRef() {
        this.parentViewRef.clear()
    }

}
