import { ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from 'class-validator';


@ValidatorConstraint({ name: 'allowedClaimAmount', async: false })
export class AllowedClaimAmount implements ValidatorConstraintInterface {
  validate(text: number, args: ValidationArguments) {
    return text > 0 // for async validations you must return a Promise<boolean> here
  }

  defaultMessage(args: ValidationArguments) {
    // here you can provide default error message if validation failed
    return 'Only claims more than RM 0 allowed';
  }
}