import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as eleaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'

@Injectable({
  providedIn: 'root'
})
export class EleaveApproverChangeDetactionService {

  public scrollCount: number = 0

  public $eleaveParentInfiniteScroll: BehaviorSubject<eleaveTypes.EleaveChildApproveBaseArgsHR> = new BehaviorSubject({
    company: '*',
    employee: '',
    leave_type: '',
    year: '',
    status: ''
  })
  public eleaveParentInfiniteScroll: Observable<eleaveTypes.EleaveChildApproveBaseArgsHR> = this.$eleaveParentInfiniteScroll.asObservable()

  private leaveDetailsSourceData = new BehaviorSubject<eleaveTypes.LeaveAllocationData>(null);
  public leaveDetailsData$ = this.leaveDetailsSourceData.asObservable();

  public leavefilterChangeDetection: BehaviorSubject<string> = new BehaviorSubject('')
  public leavefilterListener: Observable<string> = this.leavefilterChangeDetection.asObservable()

  public leaveTypefilterChangeDetection: BehaviorSubject<string> = new BehaviorSubject('')
  public leaveTypefilterListener: Observable<string> = this.leaveTypefilterChangeDetection.asObservable()

  public $eleaveSelectedEmployee: BehaviorSubject<eleaveTypes.EleaveEmployeeDetailsHRDefault> = new BehaviorSubject<eleaveTypes.EleaveEmployeeDetailsHRDefault>(null)
  public _eleaveSelectedEmployee: Observable<eleaveTypes.EleaveEmployeeDetailsHRDefault> = this.$eleaveSelectedEmployee.asObservable()


  constructor() { }


  setIncrement() {
    this.scrollCount += 1
  }

  resetIncrement() {
    this.scrollCount = 0
  }

  triggerInfiniteScroll(filter: eleaveTypes.EleaveChildApproveBaseArgsHR) {
    this.$eleaveParentInfiniteScroll.next(filter)
  }

  setLeaveDetails(leaveDetails: eleaveTypes.LeaveAllocationData) {
    this.leaveDetailsSourceData.next(leaveDetails);
  }

  setStatusChange(status: string) {
    this.leavefilterChangeDetection.next(status)
  }

  setLeaveTypeChange(type: string) {
    this.leaveTypefilterChangeDetection.next(type)
  }

  async triggerSelectedEmployeeOnHRApprove(eleaveData: eleaveTypes.EleaveEmployeeDetailsHRDefault) {
    this.$eleaveSelectedEmployee.next(eleaveData)
  }

}
