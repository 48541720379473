import { Injectable, Injector, Inject } from '@angular/core';
import { environmentType } from '../typings/enviroment'
import { cloneDeep, isEqual, uniqueId } from 'lodash'
import { ObjectMapper } from './object_mapper.service'

var minimongo = require('minimongo')

@Injectable({
    providedIn: 'root'
})
export class StorageNoSql {
    
    public db_instance : any
    constructor(private injector: Injector, @Inject('ENVIRONMENT') private environment: environmentType){
        console.log('Starting NoSql Init')
    }

    init(){
        return new Promise((resolve, reject) => {
            this.environment.storageNamespaceKeys.forEach((namespace) => {
                this.initDBNamespace(namespace).then((db) => {
                    this.db_instance = db
                    console.log(this.db_instance)
                    this.environment.storageCollectionKeys.forEach((collection) => {
                        this.initDBCollection(collection).then(() => {
                            // this.injectDummyData()
                            resolve(true)
                        })
                    })
                })
            })
        })
    }

    getDbInstance(){
        return this.db_instance
    }

    getBaseCopy(base){
        return cloneDeep(base)
    }

    getCompareBase(newobject,baseobjet){
        return isEqual(newobject, baseobjet)
    }

    getUniqueHash(prefix=""){
        return uniqueId(prefix)
    }

    mapValuesIntoObject(target: Object, source: Object){
        let temp: any = {}
        for (let key in target){
            if (source.hasOwnProperty(key)){
                temp[key] = source[key]
            }
        }

        return temp
    }
    
    // Seeding process
    initDBNamespace(collection){
        return new Promise((resolve, reject) => {
            // initiliaze collection from environment variable - Use indexed db
            let instance = minimongo.IndexedDb;

            let db = new instance({namespace: collection}, () => {
                // Successfully init namespace
                resolve(db)
            }, (error)=> {
                reject('Failed to create namespace')
                console.log('Error raising error')
            })
        })   
    }

    initDBCollection(collection){
        console.log(collection)
        return new Promise((resolve, reject) => {
            // Check for db collection
            console.log(this.db_instance.getCollectionNames())
            if (collection in this.db_instance.getCollectionNames() == false){
                // Create collection
                this.db_instance.addCollection(collection, () => {
                    resolve(true)
                }, () => {
                    reject('Failed to init collection')
                })
            }
        })
    }

    clearCollection(collection){
        // Remove collection
        return new Promise((resolve, reject) => {
            this.db_instance.removeCollection(collection, ()=> {
                resolve(true)
            }, ()=>{
                reject('Failed to remove collection')
            })
        })
    }

    removeObjectInCollection(collection, object){
        return new Promise((resolve, reject) => {
            this.db_instance[collection].remove(object, ()=> {
                this.db_instance[collection].resolveRemove(object['_id'], ()=> {
                    resolve(true)
                }, ()=> {
                    reject(true)
                })
            }, ()=> {
                reject(false)
            })
        })
    }

    fetchCollectionInstance(collection){
        return new Promise((resolve, reject) => {
            try{
                resolve(this.db_instance[collection])
            } catch (err) {
                const errorMessage: ErrorEventInit = {
                    error: new Error('Schema Not Found'),
                    message: "Requested schema instance is not found. Please initilize the schema.",
                    lineno: 125,
                    filename: 'StorageNoSql.ts'
                }

                throw new ErrorEvent('StorageNoSql', errorMessage)
            }
        })
    }

    injectDummyData(){
        return new Promise((resolve, reject) => {
            const auth = this.injector.get(ObjectMapper)
            let default_schema = auth.getdocschema('SE')
            default_schema.username = 'sample'
            default_schema.access_token = 'something cool bro'
            this.db_instance['auth'].upsert(default_schema)
        }).catch((error)=>{
            throw error.error
        })
    }
}


