import { EventEmitter, Injectable } from '@angular/core'
import { Eleave, EleaveInter } from '../../model/eleave.model'
import { ClassValidatorFormBuilderService, ClassValidatorFormGroup } from 'ngx-reactive-form-class-validator'
import { ObjectMapper } from '../../core/object_mapper.service'

export type MetaData = {
    'Eleave': MetaDataObject<'schema', 'form'>,
}

type MetaDataObject<T, K> = {
    'schema': Eleave,
    'form'?: ClassValidatorFormGroup
}

// Responsible to preparing and validating the metadata for eclaim
@Injectable({
    providedIn: 'root'
})
export class EleaveMetaData {
    constructor(
        private form: ClassValidatorFormBuilderService,
        private db: ObjectMapper
    ) { }

    initMetaData(): MetaData {
        // Prepare required metadata for parent and child table
        let eleave: Eleave = this.db.getdocschema('Eleave')
        return {
            'Eleave': {
                'schema': eleave,
                'form': this.intiELeaveDetailForm()
            }
        }
    }

    initMetaDataEdit(Eleave: Eleave): MetaData {
        // Prepare required metadata for parent and child table
        // console.log(Eleave)
        let eleave_detail: Eleave = this.db.getdocschema('Eleave')
        return {
            'Eleave': {
                'schema': eleave_detail,
                'form': this.initEditEleaveDetail(Eleave)
            }
        }
    }

    initEditEleaveDetail(data: Eleave): ClassValidatorFormGroup {
        let temp = {
            name: [data.name],
            action: [''],
            employee_no: [data.employee_no],
            employee_name: [data.employee_name],
            company: [data.company],
            leave_approver_name: [data.leave_approver_name],
            leave_approver_id: [data.leave_approver],
            description: [data.description],
            compassionateReason: [data.compassionateReason],
            reason: [''],
            posting_date: [data.posting_date],
            from_date: [data.from_date],
            to_date: [data.to_date],
            include_weekend: [data.include_weekend],
            half_day: [data.half_day],
            leave_type: [data.leave_type],
            from_time: [data.from_time],
            file_attachment: [data.file_attachment],
            to_time: [data.to_time],
            half_day_date: [data.half_day_date],
            select_weekend: [data.select_weekend],
            half_day_date_jq_data: [data.half_day_date_jq_data],
            total_leave_days: [data.total_leave_days]
        }

        return this.form.group(Eleave, temp)
    }

    intiELeaveDetailForm(): ClassValidatorFormGroup {
        return this.form.group(Eleave, {
            naming_series: 'HR-LAP-.YYYY.-',
            employee_no: [''],
            employee_name: [''],
            company: [''],
            leave_approver_name: [''],
            leave_approver_id: [''],
            description: [''],
            compassionateReason: [''],
            reason: [''],
            posting_date: [''],
            from_date: [''],
            to_date: [''],
            include_weekend: 0,
            half_day: 0,
            leave_type: [''],
            from_time: ['00:00'],
            to_time: ['00:00'],
            half_day_date: [''],
            select_weekend: [''],
            file_attachment: [''],
            half_day_date_jq_data: [''],
            total_leave_days: ['']
        })
    }

    displayWeekendDates = new EventEmitter<{ dates: string }[]>()

    // setDates(dates: { dates: string }[]) {
    //     this.displayWeekendDates.emit(dates)
    //     console.log(dates, 'dates');
    // }


    // Set the listener on form page
    activateMilleageClaim(form: ClassValidatorFormGroup) {
        // Activate mileage claim
        console.log(form)
        // if (form.contains('expense_type')){
        //     // Fetch expense type
        //     if (form.controls['expense_type'].value == 'MILEAGE'){

        //         // Avoid replacing if already mapped
        //         if (form.contains('mileage_to') || form.contains('mileage_from')){
        //             return
        //         }

        //         form.addControl('mileage_to',new FormControl('', Validators.required), {emitEvent: false})
        //         form.addControl('mileage_from', new FormControl('', Validators.required), {emitEvent: false})
        //         form.addControl('mileage_return',new FormControl(''), {emitEvent: false})
        //         form.addControl('mileage_distance',new FormControl(''), {emitEvent: false})
        //         form.addControl('transport_allowance', new FormControl(''), {emitEvent: false})
        //         form.addControl('deducted_transport_allowance',new FormControl(''), {emitEvent: false})
        //         form.addControl('mileage_description',new FormControl(''), {emitEvent: false})


        //         form.get('mileage_to')
        //         form.get('mileage_from')
        //         form.get('mileage_return')
        //         form.get('mileage_distance')
        //         form.get('transport_allowance')
        //         form.get('deducted_transport_allowance')
        //         form.get('mileage_description')
        //         form.updateValueAndValidity({emitEvent: false})
        //     } else {
        //         if (form.contains('mileage_to') || form.contains('mileage_from')){
        //             form.removeControl('mileage_to', {emitEvent: false})
        //             form.removeControl('mileage_from', {emitEvent: false})
        //             form.removeControl('mileage_return', {emitEvent: false})
        //             form.removeControl('mileage_distance', {emitEvent: false})
        //             form.removeControl('transport_allowance', {emitEvent: false})
        //             form.removeControl('deducted_transport_allowance', {emitEvent: false})
        //             form.removeControl('mileage_description', {emitEvent: false})

        //             form.clearValidators()
        //             form.updateValueAndValidity({emitEvent: false})
        //         }
        //     }
        // }
    }


}