import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { isEmpty } from 'class-validator';
import { EattendanceApiService } from 'fortunebit-staffhub/src/eattendance/services/eattendance-api';
import * as attendanceTypes from 'projects/fortunebit-staffhub/src/eattendance/services/typings/eattendance-api-typings'
import { Subject } from 'rxjs';

// export interface Months {
//   value: string,
//   description: string,
//   metadata?: NgOption
// }


@Component({
  selector: 'app-ngselect-month-select',
  templateUrl: './ngselect-month-select.component.html',
  styleUrls: ['./ngselect-month-select.component.scss']
})
export class NgselectMonthSelectComponent implements OnInit {

  // month$: Observable<Months[]>
  public monthLoading = false
  public monthSelected: Array<attendanceTypes.Months> = []
  monthInput$ = new Subject<string>()
  monthList: attendanceTypes.Months[] = []
  @ViewChild("monthSelect") public monthSelect: NgSelectComponent
  @Input('selectedMonth') public selectedMonth: Array<attendanceTypes.Months> = []
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedMonthOutput') public selectedMonthOutput = new EventEmitter<Array<attendanceTypes.Months>>()
  displayMonthsList = new EventEmitter<attendanceTypes.Months[]>()


  constructor(
    @Inject(EattendanceApiService) public eattendanceAPI: EattendanceApiService
  ) { }

  ngOnInit(): void {
    this.getMonths()
    this.loadMonths()
  }

  ngAfterViewInit(): void {
    this.trackMonthSelected()
  }

  loadMonths() {
    this.displayMonthsList.subscribe(data => {
      this.monthList = data
    })
  }

  private getMonths() {
    this.eattendanceAPI.getHodValidMonthSelection().subscribe(data => {
      this.monthList = data['message']['data']
      // this.setMonths(data['message']['data'])
    })
  }

  setMonths(months: attendanceTypes.Months[]) {
    this.displayMonthsList.emit(months)
  }

  dataInterceptor(data: attendanceTypes.Months) {
    this.monthSelected.pop()
    if (!isEmpty(data)) {
      this.monthSelected.push(data)
    } else {
      this.monthSelected = [{
        month_of_attendance: ""
      }]
    }
    return this.monthSelected
  }

  trackMonthSelected() {
    this.monthSelect.changeEvent.subscribe(month => {
      month === undefined ? month = "" : month
      // console.log(month, 'month');
      // console.log(month)
      this.selectedMonthOutput.emit(this.dataInterceptor(month))
    })
  }

  onRemove(event: any) {
    console.log('remove');
    this.trackMonthSelected()

  }









}
