import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { EleaveCore } from 'fortunebit-staffhub/src/eleave/services/eleave-core';
import { Observable, Subject } from 'rxjs';

export interface Halfday {
  id: number,
  text: string,
  halfdays: string,
  metadata?: NgOption
}

@Component({
  selector: 'app-ngselect-halfday-select',
  templateUrl: './ngselect-halfday-select.component.html',
  styleUrls: ['./ngselect-halfday-select.component.scss']
})
export class NgselectHalfdaySelectComponent implements OnInit {

  halfday$: Observable<Halfday[]>
  halfdayLoading = false
  halfdayInput$ = new Subject<string>()
  newlyAdded: Halfday[] = []

  @Input('selectedHalfday') public selectedHalfday: Halfday[] = []
  @Input('replaceSelected') public replaceSelected = new EventEmitter<Array<Halfday>>()
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedHalfdayOutput') public selectedHalfdayOutput = new EventEmitter<Array<Halfday>>()
  @ViewChild("halfdaySelect") public halfdaySelect: NgSelectComponent



  constructor(
    @Inject(EleaveCore) public eleaveCore: EleaveCore
  ) { this.loadHalfdayDates() }

  ngOnInit(): void {
  }


  ngAfterViewInit(): void {
    this.trackHalfdaySelected()
  }

  private loadHalfdayDates() {
    this.eleaveCore.displayHalfdayDates.subscribe((data) => {
      this.newlyAdded = data
    })
  }

  dataInterceptor(data: Array<Halfday>) {
    data.forEach(emp => {
      emp.metadata = this.halfdaySelect.itemsList.selectedItems.find((val) => {
        if (val.value['value'] == emp.text) {
          return true
        }
        return false
      })
    })
    return data
  }

  trackHalfdaySelected() {
    this.halfdaySelect.changeEvent
      .subscribe(data => {
        this.selectedHalfdayOutput.emit(this.dataInterceptor(data))
      })
  }

}
