import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalVariables, StorageNoSql } from '../libs';

@Injectable({
  providedIn: 'root'
})
export class AuthLogoutService {

  constructor(
    private storage: StorageNoSql,
    private router: Router,
    private global: GlobalVariables
  ) { }

  logOut() {
    this.storage.clearCollection('Auth')
      .then(() => {
        this.storage.clearCollection('User')
          .then(() => {
            this.global.clearGlobalData()
              .then(() => {
                this.storage.init().then(() => {
                  this.router.navigateByUrl('/login', { replaceUrl: true })
                })
              })
          })
      })
  }

}
