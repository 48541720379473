import { Component, OnInit, ViewChild, AfterViewInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { NgSelectComponent, NgOption } from '@ng-select/ng-select'
import { Observable, Subject, concat, of, pipe } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, pluck, skip, switchMap, tap } from 'rxjs/operators'
import { CommonServiceAPI } from 'fortunebit-staffhub/src/core/common-api.service'

export interface Company {
  value: string,
  description: string,
  metadata?: NgOption
}

@Component({
  selector: 'app-ngselect-company-select',
  templateUrl: './ngselect-company-select.component.html',
  styleUrls: ['./ngselect-company-select.component.scss'],
})
export class NgselectCompanySelectComponent implements OnInit, AfterViewInit, OnDestroy {

  public disableCompany: boolean = false

  company$: Observable<Company[]>
  companyLoading = false
  companyInput$ = new Subject<string>()
  newlyAdded: Company[] = []
  @Input('selectedCompany') public selectedCompany: Company[] = []
  @Input('replaceSelected') public replaceSelected = new EventEmitter<Array<Company>>()
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedCompanyOutput') public selectedCompanyOutput = new EventEmitter<Array<Company>>()
  @ViewChild("companySelect") public companySelect: NgSelectComponent
  constructor(
    private commonAPI: CommonServiceAPI
  ) { }

  ngOnInit() {
    console.log('started Company Filter')
  }

  ngAfterViewInit(): void {
    this.loadcompany()
    this.trackChanges()
    this.removeSelectedUser()
    this.replaceSelectedUser()
    // Init previous value
  }

  replaceSelectedUser() {
    this.replaceSelected.subscribe((replaceSelected) => {
      // Clear all selected employee
      this.companySelect.itemsList.clearSelected()

      replaceSelected.forEach((sel) => {
        sel.metadata.selected = false
        this.companySelect.itemsList.select(sel.metadata)
      })

      this.companySelect.detectChanges()
      // Attach new selected items

    })
  }

  removeSelectedUser() {
    this.removeSelected.subscribe((dataSelected: string) => {
      // check if selected user exist
      this.companySelect.itemsList.selectedItems.forEach(data => {
        if (data.value.value == dataSelected) {
          this.companySelect.itemsList.unselect(data)
          this.companySelect.detectChanges()
          // Send latest employee detail after removal
          this.selectedCompanyOutput.emit(this.companySelect.selectedValues)
        }
      })
    })
  }

  setDisableSelect(disable: boolean) {
    this.disableCompany = disable
  }

  clearAll() {
    this.companySelect.clearModel()
  }

  ngOnDestroy(): void {
    console.log('component destoryed')
    this.removeSelected.unsubscribe()
  }

  private loadcompany() {
    this.company$ = concat(
      of([]),
      this.commonAPI.getCompanyList({
        txt: '',
        doctype: 'Company',
        ignore_user_permissions: 0,
        reference_doctype: 'Expense Claim'
      }).pipe(
        tap(() => this.companyLoading = true),
        distinctUntilChanged(),
        tap(() => this.companyLoading = false),
        pluck('results')
      ),
      this.companyInput$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.companyLoading = true),
        switchMap(val => this.commonAPI.getCompanyList({
          txt: val,
          doctype: 'Company',
          ignore_user_permissions: 0,
          reference_doctype: 'Expense Claim'
        }).pipe(
          catchError(() => of([])),
          tap(() => this.companyLoading = false),
          pluck('results')
        ))
      )
    )
  }

  trackByFn(item: Company) {
    return item.value;
  }

  closeFilter() { }

  public getSelectedCompany() {
    console.log(this.companySelect.selectedValues)
  }

  dataInterceptor(data: Array<Company>) {
    data.forEach(emp => {
      emp.metadata = this.companySelect.itemsList.selectedItems.find((val) => {
        if (val.value['value'] == emp.value) {
          return true
        }

        return false
      })
    })
    return data
  }

  trackChanges() {
    this.companySelect.changeEvent
      .subscribe(data => {
        this.selectedCompanyOutput.emit(this.dataInterceptor(data))
      })
  }
}
