<div class="container" style="margin-left: 30px; margin-top: 30px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <div class="theform" style="position:relative;">
                    <form [formGroup]="form" (ngSubmit)="submitForm()">
                        <div class="add_claim_item_form" id="">
                            <div class="modal-header ">
                                <h5 class="modal-title nomargintb nopaddingtb">Add expenses</h5>
                            </div>

                            <div class="form-group">
                                <label for="expensesDate">Expense Date</label>
                                <input formControlName="expense_date" type="date" class="form-control" value=""
                                    id="expensesDate" required aria-describedby="dateHelp" placeholder="Employee IDl">
                                <i><small *ngIf="form.controls['expense_date'].invalid" style="color: red;"
                                        id="dateHelp"
                                        class="form-text text-muted">{{form.controls['expense_date'].errors.expenseDateValid}}</small></i>
                                <i><small *ngIf="form.controls['expense_date'].invalid" style="color: red;">
                                        Mandatory</small></i>
                            </div>

                            <div class="form-group">
                                <label for="selectType">Expense Claim Type</label>
                                <select formControlName="expense_type" id="selectType" class="form-control"
                                    name="claim_item_type">
                                    <option value="" disabled selected>-- Choose expenses type --</option>
                                    <option *ngFor="let card of expenseClaimCore.ExpenseList" value="{{card.value}}">
                                        {{card.value}}
                                    </option>
                                </select>
                                <i><small *ngIf="form.controls['expense_type'].invalid"
                                        style="color: red;">Mandatory</small></i>
                            </div>

                            <div class="form-group" *ngIf="mileageForm">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-6 nopadding">
                                            <label for="origin">Destination From</label>
                                            <select formControlName="mileage_from" id="origin" class="form-control"
                                                name="origin">
                                                <option value="" disabled selected>From</option>
                                                <option *ngFor="let cards of locationList" value="{{cards.location}}">
                                                    {{cards.location}}</option>
                                            </select>
                                            <i><small *ngIf="form.controls['mileage_from'].invalid"
                                                    style="color: red;">Mandatory</small></i>
                                        </div>
                                        <div class="col-6 nopadding">
                                            <label for="destination">Destination To</label>
                                            <select formControlName="mileage_to" id="destination" class="form-control">
                                                <option value="" disabled selected>To</option>
                                                <option *ngFor="let card of locationList" value="{{card.location}}">
                                                    {{card.location}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <i><small *ngIf="form.controls['mileage_to'].invalid"
                                            style="color: red;">Mandatory</small></i>
                                </div>

                                <!-- <div class="form-group">
                                    <label for="distance">Distance (KM)</label>
                                    <input formControlName="mileage_distance" type="number" min="1" class="form-control"
                                        value="form.value.mileage_distance" name="distance" id="distance"
                                        aria-describedby="distanceHelp" placeholder="{{form.value.mileage_distance}} KM"
                                        required [readonly]="readOnlyDistance">
                                    <i><small *ngIf="form.controls['mileage_distance'].invalid">(Auto
                                            Populated)</small></i>
                                </div> -->
                                <div class="form-group">
                                    <label for="distance">Distance (KM)</label>
                                    <input formControlName="mileage_distance" type="number" min="1" class="form-control"
                                        value="form.value.mileage_distance" name="distance" id="distance"
                                        aria-describedby="distanceHelp" placeholder="{{form.value.mileage_distance}} KM"
                                        required [readonly]="readOnlyDistance">
                                    <i><small *ngIf="form.controls['mileage_distance'].invalid">(Auto
                                            Populated)</small></i>
                                </div>

                                <div class="form-group">
                                    <label class="checkboxcontainer mb-0 pb-1 nopadding" for="returntrip">Return Trip
                                        <input formControlName="mileage_return" type="checkbox" value=""
                                            name="returntrip" id="returntrip" aria-describedby="returntripHelp">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="form-group">
                                    <label for="milagedescription">Milage Description</label>
                                    <textarea formControlName="mileage_description" class="form-control"
                                        id="milagedescription" name="milagedescription" rowspan="3"></textarea>
                                    <i><small *ngIf="form.controls['mileage_description'].invalid"
                                            style="color: red;">Mandatory</small></i>
                                </div>
                            </div>


                            <div class="form-group">
                                <label for="invoice">Invoice Number</label>
                                <input formControlName="invoice_number" type="text" class="form-control" value=""
                                    id="invoice" aria-describedby="invoicehelp" placeholder="Invoice Number">
                                <i><small *ngIf="form.controls['invoice_number'].invalid"
                                        style="color: red;">Mandatory</small></i>
                            </div>
                            <div class="form-group">
                                <label for="description">Description</label>
                                <textarea formControlName="description" class="form-control" id="description"
                                    rowspan="3"></textarea>
                                <i><small *ngIf="form.controls['description'].invalid"
                                        style="color: red;">Mandatory</small></i>
                            </div>

                            <div class="form-group" *ngIf="mileageForm">
                                <div class="form-group">
                                    <label for="transportamount">Transport Allowance</label>
                                    <input formControlName="transport_allowance" type="number" class="form-control"
                                        value="" id="transportamount" aria-describedby="transportamountHelp"
                                        placeholder="RM0.00" readonly>
                                    <i><small>(Auto Calculated)</small></i>
                                </div>
                                <div class="form-group">
                                    <label for="transportdeducted">Deducted Allowance</label>
                                    <input formControlName="deducted_transport_allowance" type="number"
                                        class="form-control" value="" id="transportdeducted"
                                        aria-describedby="transportdeductedHelp" placeholder="RM0.00" readonly>
                                    <i><small>(Auto Calculated)</small></i>
                                </div>
                            </div>

                            <div class="form-group none" data-fieldshowhide="entertainment">
                                <label for="budgetrange" class="form-label">Expenses Budget</label> <i>RM0 ~ RM300</i>
                                <br>

                                <input type="range" step="0.1" class="form-range" id="budgetrange" name="budgetrange"
                                    value="0.10" min="0.10" max="300.00">
                                <i><small id="amountHelp" class="form-text text-muted"></small></i>
                            </div>

                            <div class="form-group">
                                <label for="amount">Amount(RM)</label>
                                <input formControlName="amount" type="number" class="form-control" value=""
                                    name="amount" id="amount" aria-describedby="amountHelp" placeholder="RM0.00"
                                    [disabled]="disableAmount" [readonly]="disableAmount">
                                <i><small *ngIf="form.controls['amount'].invalid"
                                        style="color: red;">Mandatory</small></i>
                            </div>

                            <div class="form-group">
                                <label for="proof">Proof of expenses</label>
                                <div *ngIf="!gotAttachment">
                                    <input type="file" class="form-control" value="" id="proof"
                                        aria-describedby="amountHelp" placeholder="Proof of expenses"
                                        accept="image/*, application/pdf" (change)="selectedFile($event)">
                                </div>
                                <div *ngIf="gotAttachment">
                                    <input type="text" value="{{selected_files['name']}}" readonly>
                                    <i (click)="removeContent()">Remove</i>
                                </div>
                                <i><small id="amountHelp" class="form-text text-muted"></small></i>
                            </div>
                            <div class="row">
                                <div class="col-12 nopadding">
                                    <button [disabled]="!form.valid" type="submit"
                                        [ngClass]="{'btn-disabled': form.valid == false}"
                                        class="btn-c btn-blue w-100 btn-lg mt-4 mb-4">
                                        Save Claim
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>