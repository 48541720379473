import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';

export interface Year {
  value: number
  metadata?: NgOption
}

@Component({
  selector: 'app-ngselect-year-leave-select',
  templateUrl: './ngselect-year-leave-select.component.html',
  styleUrls: ['./ngselect-year-leave-select.component.scss']
})
export class NgselectYearLeaveSelectComponent implements OnInit {

  year$: Observable<Year[]>[]
  yearLoading = false
  yearInput$ = new Subject<string>()
  public monthLoading = false

  @ViewChild("yearSelect") public yearSelect: NgSelectComponent
  @Input('selectedYear') public selectedYear: Year[] = []
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedYearOutput') public selectedYearOutput = new EventEmitter<Array<Year>>()
  displayYearList = new EventEmitter<Year[]>()

  constructor() { }

  ngOnInit(): void {
    this.getAllYears()
  }

  getAllYears() {
    this.year$ = this.loadYears(2)
    // this.monthList = this.all_months
  }

  loadYears(back) {
    let years = []
    const year = new Date().getFullYear();
    for (let i = 0; i < back; i++) {
      years.push({ value: (year - back + i + 1) });
    }
    return years
  }

  clearAll() {
    this.yearSelect.clearModel()
  }

}
