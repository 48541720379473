import { Injectable } from '@angular/core';
import { NgselectAdvanceCategoryComponent } from '@desktop-common-comp/components/ngselect-advance-category/ngselect-advance-category.component';
import { NgselectEleaveCategoryComponent } from '@desktop-common-comp/components/ngselect-eleave-category/ngselect-eleave-category.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EleaveCategoryService {

  public eleaveCategoryInstance: NgselectEleaveCategoryComponent
  public advanceCategoryInstance: NgselectAdvanceCategoryComponent
  public $eleaveCategoryChangeDetact: Subject<string> = new Subject()
  public $advanceCategoryChangeDetact: Subject<string> = new Subject()
  public eleaveCategorySubscription: Subscription[] = []

  constructor() { }

  setEleaveCategorySelectInstance(component: NgselectEleaveCategoryComponent) {
    // console.log({ component });
    this.eleaveCategoryInstance = component
    let eleaveSub = this.eleaveCategoryInstance.selectedCategoryOutput.pipe(
      distinctUntilChanged(),
      debounceTime(500)
    ).subscribe(data => {
      this.$eleaveCategoryChangeDetact.next(data)
    })
    this.eleaveCategorySubscription.push(eleaveSub)
  }

  unsubscribeEleaveCategory() {
    if (this.eleaveCategorySubscription.length > 0) {
      this.eleaveCategorySubscription.map(sub => {
        sub.unsubscribe()
      })
    }
  }

  setAdvanceCategorySelectInstance(component: NgselectAdvanceCategoryComponent) {
    // console.log({ component });
    this.advanceCategoryInstance = component
    let advSub = this.advanceCategoryInstance.selectedCategoryOutput.pipe(
      distinctUntilChanged(),
    ).subscribe(data => {
      this.$advanceCategoryChangeDetact.next(data)
    })
    this.eleaveCategorySubscription.push(advSub)
  }


}
