import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { EleaveCore } from 'fortunebit-staffhub/src/eleave/services/eleave-core';
import { Observable, Subject } from 'rxjs';

export interface Weekend {
  id: number,
  text: string,
  description: string,
  metadata?: NgOption
}

@Component({
  selector: 'app-ngselect-weekend-select',
  templateUrl: './ngselect-weekend-select.component.html',
  styleUrls: ['./ngselect-weekend-select.component.scss']
})
export class NgselectWeekendSelectComponent implements OnInit {

  weekend$: Observable<Weekend[]>
  weekendLoading = false
  weekendInput$ = new Subject<string>()
  newlyAdded: Weekend[] = []

  @Input('selectedWeekend') public selectedWeekend: Weekend[] = []
  @Input('replaceSelected') public replaceSelected = new EventEmitter<Array<Weekend>>()
  @Input('removeSelected') public removeSelected = new EventEmitter<string>()
  @Output('selectedWeekendOutput') public selectedWeekendOutput = new EventEmitter<Array<Weekend>>()
  @ViewChild("weekendSelect") public weekendSelect: NgSelectComponent


  constructor(
    @Inject(EleaveCore) public eleaveCore: EleaveCore
  ) { this.loadWeekend() }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.trackWeekendSelected()
  }



  private loadWeekend() {
    this.eleaveCore.displayWeekendDates.subscribe((data) => {
      this.newlyAdded = data
    })
  }

  dataInterceptor(data: Array<Weekend>) {
    data.forEach(emp => {
      emp.metadata = this.weekendSelect.itemsList.selectedItems.find((val) => {
        if (val.value['value'] == emp.text) {
          return true
        }
        return false
      })
    })
    return data
  }

  trackWeekendSelected() {
    this.weekendSelect.changeEvent
      .subscribe(data => {
        this.selectedWeekendOutput.emit(this.dataInterceptor(data))
      })
  }

}
