import { Injectable } from '@angular/core'
import { Observable, Observer } from 'rxjs'
import { HttpServiceService } from './http-service.service'
import { AlertController, AlertOptions } from '@ionic/angular'
import { environment } from '../env/environment'
import { GetCompanyListInput } from '../typings/common-api.typings'

interface ServerVersion {
    app_version: string
    server_version: string
}

@Injectable({
    providedIn: 'root'
})
export class CommonServiceAPI {

    constructor(
        private http: HttpServiceService,
        private alert: AlertController
    ) { }

    getEmployeeList(search) {
        return new Observable((observer: Observer<any>) => {
            this.http.post('/users/getEmployeeList', search).then(data => {
                observer.next(data)
                observer.complete()
            }).catch(error => {
                observer.error(error)
            })
        })
    }

    getCompanyList(search: GetCompanyListInput) {
        return new Observable((observer: Observer<any>) => {
            this.http.post('/users/getCompanyList', search).then(data => {
                observer.next(data)
                observer.complete()
            }).catch(error => {
                observer.error(error)
            })
        })
    }

    getVersion() {
        return new Observable((observer: Observer<any>) => {
            this.http.get('/users/getVersion').then(data => {
                observer.next(data)
                observer.complete()
            }).catch(error => {
                observer.error(error)
            })
        })
    }

    async checkUpdateAvailable() {
        try {
            let server_version: any = await this.http.get('/users/getVersion')

            let current_version = environment.appVersion

            if (server_version['app_version'] != current_version) {
                let alertInstance = await this.alert.create({
                    'id': 'someIdVersion8789',
                    'backdropDismiss': false,
                    'header': 'App Version',
                    'message': 'Your app version seems to be older. Please update them through App/Play Store',
                    'keyboardClose': false
                })

                alertInstance.present()
            }

            return true
        } catch (error) {
            let alertInstance = await this.alert.create({
                'id': 'someIdVersion8789',
                'backdropDismiss': false,
                'header': 'App Version',
                'message': 'Unable to check server version. Please make sure your connection is stable. Close the app and open again.',
                'keyboardClose': false
            })

            alertInstance.present()

            return false
        }
    }

    async getObstructingMessage() {
        let alterInstance = await this.alert.getTop()

        if (alterInstance && alterInstance.id == 'someIdVersion8789') {
            let server_version: any = await this.http.get('/users/getVersion')

            let current_version = environment.appVersion

            if (server_version['app_version'] == current_version) {
                alterInstance.dismiss()
            }
        }
    }
}