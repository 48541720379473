import { EventEmitter, Injectable } from '@angular/core';
import { NgselectCompanySelectComponent } from '@desktop-common-comp/components/ngselect-company-select/ngselect-company-select.component';
import { NgselectEmployeeSelectComponent } from '@desktop-common-comp/components/ngselect-employee-select/ngselect-employee-select.component';
import { NgselectYearSelectComponent } from '@desktop-common-comp/components/ngselect-year-select/ngselect-year-select.component';
import { cs_log } from '@handler/logger.handler';
import { cloneDeep } from 'lodash';
import * as eleaveTypes from 'projects/fortunebit-staffhub/src/eleave/services/typings/eleave-api.typings'
import { BehaviorSubject } from 'rxjs';


export interface Year {
  value: number,
}

@Injectable({
  providedIn: 'root'
})
export class EleaveFilterService {


  public displayYears = new EventEmitter<Year[]>()
  public eleaveFilterMeta: eleaveTypes.EleaveHRFilterMeta = new eleaveTypes.EleaveHRFilterMeta()
  public $eleaveFilterChangeDetect: BehaviorSubject<eleaveTypes.EleaveHRFilterMeta> = new BehaviorSubject(cloneDeep(this.eleaveFilterMeta))
  public employeeSelectInstance: NgselectEmployeeSelectComponent
  public companySelectInstance: NgselectCompanySelectComponent
  public yearSelectInstance: NgselectYearSelectComponent

  // Year Change
  public removeYearChangeDetection: EventEmitter<boolean> = new EventEmitter(false)


  constructor() { }

  triggerChanges() {
    this.$eleaveFilterChangeDetect.next(cloneDeep(this.eleaveFilterMeta))
  }

  // ---------------------- Company selection ----------------------------- \\
  setCompanySelectInstance(component: NgselectCompanySelectComponent) {
    this.companySelectInstance = component
    this.companySelectInstance.selectedCompanyOutput.subscribe(data => {
      this.setupCompanySelect(data)
    })
    cs_log(this, 'Employee Select Mapped to Service')
  }

  setupCompanySelect(comp: Array<eleaveTypes.Company>) {
    // Listner for refresh
    this.serializeCompany(comp)
      .then(out => {
        this.eleaveFilterMeta.company = out
        // Inform changes
        this.triggerChanges()
      }).catch(err => {
        console.log(err)
      })
  }

  serializeCompany(data: Array<eleaveTypes.Company>): Promise<string> {
    return new Promise((resolve, reject) => {
      // Expected data to be in list of dictionary
      // this.selectedCompanyDict = data
      // Convert string to concat string
      let selectedCompany = '*'

      data.forEach(el => {
        // Reset filter
        if (selectedCompany == '*') {
          selectedCompany = `${el['value']}`
        } else {
          selectedCompany = `${selectedCompany},${el['value']}`
        }
      })

      resolve(selectedCompany)
    })
  }

  // ---------------------- Employee selection ----------------------------- \\
  setEmployeeSelectInstance(component: NgselectEmployeeSelectComponent) {
    this.employeeSelectInstance = component
    this.employeeSelectInstance.selectedEmployeeOutput.subscribe(data => {
      this.setupEmployeeSelect(data)
    })
    cs_log(this, 'Company Select Mapped to Service')
  }

  setupEmployeeSelect(empl: Array<eleaveTypes.Employee>) {
    // Listner for refresh
    this.serializeEmployee(empl)
      .then(out => {
        this.eleaveFilterMeta.employee = out
        // Inform changes
        this.triggerChanges()
      }).catch(err => {
        console.log(err)
      })
  }

  serializeEmployee(data: Array<eleaveTypes.Employee>): Promise<string> {
    return new Promise((resolve, reject) => {
      // Expected data to be in list of dictionary
      // this.selectedCompanyDict = data
      // Convert string to concat string
      let selectedEmployee = ''

      data.forEach(el => {
        // Reset filter
        if (selectedEmployee == '') {
          selectedEmployee = `${el['value']}`
        } else {
          selectedEmployee = `${selectedEmployee},${el['value']}`
        }
      })

      resolve(selectedEmployee)
    })
  }


  // ---------------------- Year selection ----------------------------- \\

  setYearSelectInstance(component: NgselectYearSelectComponent) {
    this.yearSelectInstance = component
    this.yearSelectInstance.selectedYearOutput.subscribe(data => {
      this.setupYearSelect(data)
    })
    cs_log(this, 'Year Select Mapped to Service')
  }


  setupYearSelect(year: eleaveTypes.Year | number) {
    // Listner for refresh
    if (year != 0) {
      this.serializeYear(year)
        .then(out => {
          this.eleaveFilterMeta.creation = out
          // Inform changes
          this.triggerChanges()
        }).catch(err => {
          console.log(err)
        })
    } else {
      this.eleaveFilterMeta.creation = year
      // Inform changes
      this.triggerChanges()
    }
  }

  serializeYear(data: eleaveTypes.Year | number): Promise<number> {
    return new Promise((resolve, reject) => {
      resolve(data['value'])
    })
  }



}
