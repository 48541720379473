import { Inject, Injectable } from "@angular/core";
import { HttpServiceService } from "../../core/http-service.service";
import { Observable, Observer } from "rxjs";
import * as attendanceTypes from "./typings/eattendance-api-typings";

@Injectable({
  providedIn: 'root'
})

export class EattendanceApiService {

  constructor(
    @Inject(HttpServiceService) private http: HttpServiceService
  ) { }


  getUserListAttendance(data: attendanceTypes.UserListAttendanceInput) {
    return new Observable((observer: Observer<Array<attendanceTypes.AttendanceListDatas>>) => {
      this.http.post('/users/getUserListAttendance', data).then((data: Array<attendanceTypes.AttendanceListDatas>) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  setUserChildAttendanceUpdateRemark(data) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/setUserChildAttendanceUpdateRemark', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getPendingHodParentList(data: attendanceTypes.HODParentSummaryInput) {
    return new Observable((observer: Observer<Array<attendanceTypes.hodApprovalParentListType>>) => {
      this.http.post('/users/get_hod_list_attendance_parent', data).then((data: Array<attendanceTypes.hodApprovalParentListType>) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getPendingHodApprovalChildList(data: attendanceTypes.HODApprovalChildListArgs) {
    return new Observable((observer: Observer<Array<attendanceTypes.AttendanceListDatas>>) => {
      this.http.post('/users/getHodApprovalChildList', data).then((data: Array<attendanceTypes.AttendanceListDatas>) => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  setUserChildAttendanceIndividualRejectRemark(data: attendanceTypes.setAttendanceRejectRemark) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/setAttendanceIndividualRejectRemark', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getTotalWorkingDays(data: attendanceTypes.TotalMonthInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getAttendanceTotalWorkingDays', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getAttendanceApprovarSummaryList(data: attendanceTypes.HODParentSummaryInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getAttendanceApprovalSummaryList', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getAttendanceUserSummaryList(data: attendanceTypes.UserAttendanceSummaryInput) {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getAttendanceUserSummary', data).then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

  getHodValidMonthSelection() {
    return new Observable((observer: Observer<any>) => {
      this.http.post('/users/getHodValidMonthSelection').then(data => {
        observer.next(data)
        observer.complete()
      }).catch(error => {
        observer.error(error)
      })
    })
  }

}
