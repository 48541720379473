<div class="text-center" style="display: flex;justify-content: space-around;">

  <div class="row" style="justify-content: space-between;">
    <div class="col-4" style="display:flex">
      <button (click)="onChangeLeaveType('Annual Leave')"
        [ngClass]="{'btn-success':'Annual Leave' === selectedLeaveType}" type="button" type="button" class="btn"
        style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Annual
        Leave</button>
    </div>
    <div class="col-4 " style="display:flex">
      <button (click)="onChangeLeaveType('Medical Leave')"
        [ngClass]="{'btn-success':'Medical Leave' === selectedLeaveType}" type="button" type="button" class="btn"
        style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Medical
        Leave</button>
    </div>
    <div class="col-4 " style="display:flex">
      <button (click)="onChangeLeaveType('Leave Without Pay')"
        [ngClass]="{'btn-success':'Leave Without Pay' === selectedLeaveType}" type="button" type="button" class="btn"
        style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">Leave
        Without Pay</button>
    </div>

  </div>
  <div class="row" style="margin-left: 25px;">
    <div class="col-4 col-md-2" *ngIf="advanceTrigger ==  true" style="display:flex">
      <button type="button" type="button" class="btn btn-success"
        style="height: fit-content; margin: 5px; white-space: nowrap; font-weight: 600; box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;">{{
        selectedLeaveType }} </button>
    </div>

  </div>
</div>