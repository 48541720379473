import { Allow, IsEmail, IsNotEmpty } from 'class-validator'

export interface LoginType {
  username: string
  password: string
  schema_doctype: string
}

export class Login implements LoginType {
  @IsNotEmpty()
  username: string
  @IsNotEmpty()
  password: string
  @Allow()
  schema_doctype: string = 'Login'
}

export interface AuthType {
  username: string
  auth_user: string
  access_token: string
  allowed_module: Array<string>
  schema_doctype: string,
  refresh_token: string,
  _id: string
}

export class Auth implements AuthType {
  @IsNotEmpty()
  @IsEmail()
  username: string
  @Allow()
  auth_user: string
  @IsNotEmpty()
  access_token: string
  @IsNotEmpty()
  refresh_token: string
  @Allow()
  allowed_module: Array<string>
  @Allow()
  schema_doctype: string = 'Auth'
  @Allow()
  _id: string
}

export interface UserType {
  user_role: string
  employee_id: string
  employee_name: string
  reports_to_id: string
  reports_to_name: string
  company: string
  schema_doctype: string
  _id: string
  is_leave_approver: boolean
  is_hr: boolean
}

export class User implements UserType {
  @IsNotEmpty()
  user_role: string
  @IsNotEmpty()
  employee_id: string
  @Allow()
  employee_name: string
  @Allow()
  reports_to_id: string
  @Allow()
  reports_to_name: string
  @Allow()
  company: string
  @Allow()
  schema_doctype: string = 'User'
  @Allow()
  _id: string
  @Allow()
  is_leave_approver: boolean
  @Allow()
  is_hr: boolean
}

export class ForgotPassword {
  @IsNotEmpty()
  @IsEmail()
  username: string
}


