import { Injectable, Inject } from '@angular/core';
import { MessageService, Message } from 'primeng/api'

@Injectable({
  providedIn: 'root'
})

// const message: Message = {
//   styleClass
// }

export class ToastService {

  constructor(
    @Inject(MessageService) private MessageService: MessageService
  ) { // Test run
    console.log('started')
    // this.genericToast('info', 'Application Started')
  }


  successToast(detail: string, summary: string = '') {
    // console.log('executing toast')
    this.MessageService.add({
      severity: 'success',
      closable: true,
      id: 'global',
      detail: detail,
      summary: summary,
      contentStyleClass: 'p-toast.p-toast-message.p-toast-message-success'
      // sticky: true
    })
  }

  errorToast(detail: string, summary: string = '') {
    this.MessageService.add({
      severity: 'error',
      closable: true,
      id: 'global',
      detail: detail,
      summary: summary
      // sticky: true
    })
  }

  genericToast(severity: string, detail: string,
    summary: string = '', key: string = 'b', closable: boolean = true) {
    this.MessageService.add({
      severity: severity,
      closable: closable,
      id: key,
      detail: detail,
      summary: summary
    })
  }
}
